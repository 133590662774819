import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Search, RefreshCw, ChevronLeft, ChevronRight } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const AdminDashboard = () => {
  const [cadthStats, setCadthStats] = useState({});
  const [pcpaStats, setPcpaStats] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lastSearchTerm, setLastSearchTerm] = useState('');
  const [cadthReviews, setCadthReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage] = useState(100);
  const [isPcodrFilter, setIsPcodrFilter] = useState(false);

  useEffect(() => {
    fetchStats();
  }, [isPcodrFilter]);

  const fetchStats = async (search = '', start = null, end = null) => {
    setIsLoading(true);
    try {
      const params = { search, isPcodr: isPcodrFilter };
      if (start) params.startDate = start.toISOString().split('T')[0];
      if (end) params.endDate = end.toISOString().split('T')[0];

      const [cadthResponse, pcpaResponse] = await Promise.all([
        axios.get('/api/admin/cadth-stats', { params }),
        axios.get('/api/admin/pcpa-stats', { params })
      ]);
      console.log('CADTH Stats:', cadthResponse.data);
      console.log('pCPA Stats:', pcpaResponse.data);
      setCadthStats(cadthResponse.data);
      setPcpaStats(pcpaResponse.data);
      setCadthReviews(cadthResponse.data.reviews || []);
      setLastSearchTerm(search);
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
    setIsLoading(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchStats(searchTerm, startDate, endDate);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setStartDate(null);
    setEndDate(null);
    fetchStats('', null, null);
  };

  const handleDateChange = (date, isStart) => {
    if (isStart) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    fetchStats(searchTerm, isStart ? date : startDate, isStart ? endDate : date);
  };

  const handlePcodrFilterChange = (e) => {
    setIsPcodrFilter(e.target.checked);
  };

  const StatCard = ({ title, value, description }) => (
    <div className="bg-white shadow rounded-lg p-5">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <div className="text-2xl font-bold mb-1">{value}</div>
      <p className="text-sm text-gray-600">{description}</p>
    </div>
  );

  const YearlyChart = ({ data, title, color, dataKey = "count", yAxisLabel }) => (
    <div className="bg-white shadow rounded-lg p-5 mt-6">
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={dataKey} stroke={color} activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  const prepareRecommendationTypeData = (recommendationTypes) => {
    return recommendationTypes.map(type => ({
      name: type._id,
      value: type.count
    }));
  };

  const RecommendationTypePieChart = ({ data }) => {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

    return (
      <div className="bg-white shadow rounded-lg p-5 mt-6">
        <h2 className="text-xl font-semibold mb-4">Recommendation Types</h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  const CADTHReviewsTable = ({ reviews }) => {
    const indexOfLastReview = currentPage * reviewsPerPage;
    const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
    const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

    const totalPages = Math.ceil(reviews.length / reviewsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const truncate = (str, n) => {
      if (typeof str !== 'string') return '';
      return str.length > n ? str.substr(0, n-1) + '...' : str;
    };

    const formatDate = (dateString) => {
      if (!dateString) return 'N/A';
      return new Date(dateString).toLocaleDateString();
    };

    return (
      <div className="bg-white shadow rounded-lg p-5 mt-6">
        <h2 className="text-xl font-semibold mb-4">CADTH Reviews Included</h2>
        <p className="text-sm text-gray-600 mb-2">Total reviews: {reviews.length}</p>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Brand Name</th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Generic Name</th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Therapeutic Area</th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Manufacturer</th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Submission</th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Recommendation</th>
                <th className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentReviews.map((review, index) => (
                <tr key={index}>
                  <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500">{indexOfFirstReview + index + 1}</td>
                  <td className="px-2 py-4 whitespace-nowrap text-sm" title={review.brand_name || ''}>{truncate(review.brand_name, 20)}</td>
                  <td className="px-2 py-4 whitespace-nowrap text-sm" title={review.generic_name || ''}>{truncate(review.generic_name, 20)}</td>
                  <td className="px-2 py-4 whitespace-nowrap text-sm" title={review.therapeutic_area || ''}>{truncate(review.therapeutic_area, 20)}</td>
                  <td className="px-2 py-4 whitespace-nowrap text-sm" title={review.manufacturer_name || ''}>{truncate(review.manufacturer_name, 20)}</td>
                  <td className="px-2 py-4 whitespace-nowrap text-sm">{formatDate(review.date_submission_received)}</td>
                  <td className="px-2 py-4 whitespace-nowrap text-sm">{formatDate(review.date_recommendation_issued)}</td>
                  <td className="px-2 py-4 whitespace-nowrap text-sm" title={review.recommendation_type || ''}>{truncate(review.recommendation_type, 15)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 border border-gray-300 rounded-md disabled:opacity-50"
          >
            <ChevronLeft size={20} />
          </button>
          <span className="text-sm text-gray-700">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border border-gray-300 rounded-md disabled:opacity-50"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <RefreshCw className="animate-spin h-8 w-8 text-blue-500" />
      </div>
    );
  }

  const cadthYearlyData = cadthStats.reviewsPerYear?.map(item => ({
    year: item._id,
    count: item.count
  })).filter(item => item.count > 0) || [];

  const cadthAvgDurationData = cadthStats.averageDurationPerYear?.map(item => ({
    year: item._id,
    avgDuration: Math.max(0, Math.round(item.averageDuration))
  })).filter(item => item.avgDuration > 0) || [];

  const recommendationTypeData = prepareRecommendationTypeData(cadthStats.recommendationTypes || []);

  const pcpaYearlyData = pcpaStats.negotiationsPerYear?.map(item => ({
    year: item._id,
    count: item.count
  })).filter(item => item.count > 0) || [];

  const pcpaAvgDurationData = pcpaStats.averageDurationPerYear?.map(item => ({
    year: item._id,
    avgDuration: Math.max(0, Math.round(item.averageDuration))
  })).filter(item => item.avgDuration > 0) || [];

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-semibold mb-4">Admin Dashboard</h1>

      <div className="flex flex-col mb-6">
        <div className="flex mb-2">
          <input
            type="text"
            placeholder="Search by brand name, generic name, therapeutic area, or manufacturer..."
            value={searchTerm}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleSearch}
            className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-200"
          >
            <Search size={20} />
          </button>
        </div>
        <div className="flex space-x-4">
          <DatePicker
            selected={startDate}
            onChange={(date) => handleDateChange(date, true)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => handleDateChange(date, false)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        
        <div className="flex items-center mt-2">
          <input
            type="checkbox"
            id="pcodrFilter"
            checked={isPcodrFilter}
            onChange={handlePcodrFilterChange}
            className="mr-2"
          />
          <label htmlFor="pcodrFilter" className="text-sm text-gray-600">
            Show only pCODR drugs
          </label>
        </div>
      </div>

      {lastSearchTerm && (
        <p className="text-sm text-gray-600 mb-4">
          Showing results for: "{lastSearchTerm}"
          <button
            onClick={handleClearSearch}
            className="ml-2 text-blue-500 hover:underline"
          >
            Clear search
          </button>
        </p>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Total CADTH Reviews"
          value={cadthStats.totalReviews || 0}
          description="Number of drug reviews conducted by CADTH"
        />
        <StatCard
          title="Average CADTH Review Time"
          value={cadthStats.averageReviewTime !== null ? `${cadthStats.averageReviewTime} days` : 'N/A'}
          description={`Based on ${cadthStats.completedReviews || 0} completed reviews`}
        />
        <StatCard
          title="Total pCPA Negotiations"
          value={pcpaStats.totalNegotiations || 0}
          description="Number of drug price negotiations conducted by pCPA"
        />
        <StatCard
          title="Average Negotiation Time"
          value={pcpaStats.averageNegotiationTime !== null ? `${pcpaStats.averageNegotiationTime} days` : 'N/A'}
          description={`Based on ${pcpaStats.completedNegotiations || 0} completed negotiations`}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <YearlyChart
          data={cadthYearlyData}
          title="CADTH Reviews per Year"
          color="#8884d8"
          yAxisLabel="Number of Reviews"
        />
        <YearlyChart
          data={cadthAvgDurationData}
          title="Average CADTH Review Duration per Year"
          color="#82ca9d"
          dataKey="avgDuration"
          yAxisLabel="Average Duration (days)"
        />
        <RecommendationTypePieChart data={recommendationTypeData} />
        <YearlyChart
          data={pcpaYearlyData}
          title="pCPA Negotiations per Year"
          color="#8884d8"
          yAxisLabel="Number of Negotiations"
        />
        <YearlyChart
          data={pcpaAvgDurationData}
          title="Average pCPA Negotiation Duration per Year"
          color="#82ca9d"
          dataKey="avgDuration"
          yAxisLabel="Average Duration (days)"
        />
        <div></div>
      </div>

      <CADTHReviewsTable reviews={cadthReviews} />
    </div>
  );
};

export default AdminDashboard;