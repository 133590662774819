import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Search, ChevronLeft, ChevronRight, RefreshCw } from 'lucide-react';
import { Toaster, toast } from 'react-hot-toast';

const ODBDrugsAdmin = () => {
    const [drugs, setDrugs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [totalDrugs, setTotalDrugs] = useState(0);
    const [isCrawling, setIsCrawling] = useState(false);

    const fetchDrugs = async (page = 1, search = '') => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/admin/odb-drugs', {
                params: { page, limit: 10, search }
            });
            setDrugs(response.data.drugs);
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.currentPage);
            setTotalDrugs(response.data.totalDrugs);
        } catch (error) {
            console.error('Error fetching drugs:', error);
            setDrugs([]);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchDrugs(1, '');
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchDrugs(1, searchTerm);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchDrugs(newPage, searchTerm);
    };

    const triggerCrawler = async () => {
        setIsCrawling(true);
        try {
            await axios.post('/api/admin/crawl-odb');
            toast.success('Crawler completed successfully!');
            fetchDrugs(1, searchTerm); // Refresh the list after crawling
        } catch (error) {
            console.error('Error triggering crawler:', error);
            toast.error('Error triggering crawler. Please try again.');
        } finally {
            setIsCrawling(false);
        }
    };

    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <Toaster position="top-right" />
            <h1 className="text-2xl font-semibold mb-6">ODB Drugs</h1>

            <div className="flex justify-between items-center mb-6">
                <form onSubmit={handleSearch} className="flex-grow mr-4">
                    <div className="flex">
                        <input
                            type="text"
                            placeholder="Search drugs..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-200">
                            <Search size={20} />
                        </button>
                    </div>
                </form>

                <button
                    onClick={triggerCrawler}
                    disabled={isCrawling}
                    className={`bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-200 ${
                        isCrawling ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                >
                    {isCrawling ? (
                        <RefreshCw size={20} className="animate-spin" />
                    ) : (
                        'Run Crawler'
                    )}
                </button>
            </div>

            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
            ) : drugs.length === 0 ? (
                <div className="text-center py-4">No drugs found.</div>
            ) : (
                <>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th className="py-3 px-4 text-center">ID</th>
                                    <th className="py-3 px-4 text-center">Name</th>
                                    <th className="py-3 px-4 text-center">Manufacturer ID</th>
                                    <th className="py-3 px-4 text-center">Strength</th>
                                    <th className="py-3 px-4 text-center">Dosage Form</th>
                                    <th className="py-3 px-4 text-center">Generic Name</th>
                                    <th className="py-3 px-4 text-center">Category</th>
                                    <th className="py-3 px-4 text-center">SubCategory</th>
                                    <th className="py-3 px-4 text-center">Price</th>
                                    <th className="py-3 px-4 text-center">Listing Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {drugs.map((drug) => (
                                    <tr key={drug.id} className="border-b border-gray-200 hover:bg-gray-50">
                                        <td className="py-3 px-4 text-center">{drug.id}</td>
                                        <td className="py-3 px-4 text-center">{drug.name}</td>
                                        <td className="py-3 px-4 text-center">{drug.manufacturerId}</td>
                                        <td className="py-3 px-4 text-center">{drug.strength}</td>
                                        <td className="py-3 px-4 text-center">{drug.dosageForm}</td>
                                        <td className="py-3 px-4 text-center">{drug.genericName}</td>
                                        <td className="py-3 px-4 text-center">{drug.category}</td>
                                        <td className="py-3 px-4 text-center">{drug.subCategory}</td>
                                        <td className="py-3 px-4 text-center">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD' }).format(drug.individualPrice)}</td>
                                        <td className="py-3 px-4 text-center">
                                            {new Date(drug.listingDate).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="mt-6 flex justify-between items-center">
                        <span>
                            Showing {drugs.length} of {totalDrugs} drugs | Page {currentPage} of {totalPages}
                        </span>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                                disabled={currentPage === 1}
                                className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition duration-200"
                            >
                                <ChevronLeft size={20} />
                            </button>
                            <button
                                onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                                disabled={currentPage === totalPages}
                                className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition duration-200"
                            >
                                <ChevronRight size={20} />
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ODBDrugsAdmin;