import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Search, Save, Trash2, ExternalLink } from 'lucide-react';
import { Card, CardHeader, CardContent, CardFooter } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../components/ui/dialog";
import { Switch } from "../components/ui/switch";
import { Label } from "../components/ui/label";
import { Toaster, toast } from 'react-hot-toast';

const AdminPublications = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [savedPublications, setSavedPublications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchSavedPublications();
  }, []);

  const fetchSavedPublications = async () => {
    try {
      const response = await axios.get('/api/admin/publications');
      setSavedPublications(response.data);
    } catch (error) {
      console.error('Error fetching saved publications:', error);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/admin/publications/search?query=${searchTerm}`);
      toast.success(`Found ${response.data.length} publications`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching publications:', error);
        toast.error('Error searching publications');
    }
    setIsLoading(false);
  };

  const handleSave = async (publication) => {
    try {
      await axios.post('/api/admin/publications/store', publication);
      fetchSavedPublications();
      setSearchResults(searchResults.filter(p => p.pmid !== publication.pmid));
      toast.success(`Publication "${publication.title}" saved successfully`);
    } catch (error) {
      console.error('Error saving publication:', error);
    }
  };

  const handleDelete = async (pmid) => {
    try {
      await axios.delete(`/api/admin/publications/${pmid}`);
      fetchSavedPublications();
      toast.success(`Publication with PMID ${pmid} deleted successfully`);
    } catch (error) {
      console.error('Error deleting publication:', error);
    }
  };

  const handlePublicationUpdate = (updatedPublication) => {
    setSavedPublications(prevPublications => 
      prevPublications.map(pub => 
        pub.pmid === updatedPublication.pmid ? updatedPublication : pub
      )
    );
  };

  const renderAbstract = (abstract) => {
    if (typeof abstract === 'string') {
      return abstract;
    } else if (abstract && abstract.text) {
      return (
        <>
          {abstract.label && <strong>{abstract.label}: </strong>}
          {abstract.text}
        </>
      );
    }
    return 'No abstract available';
  };

  const PublicationDetailsModal = ({ publication, onUpdate }) => {
    const [isExtracting, setIsExtracting] = useState(false);
    const [localPublication, setLocalPublication] = useState(publication);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    useEffect(() => {
      setLocalPublication(publication);
    //   console.log(publication);
    }, [publication]);

    const handleExtractOutcomes = async () => {
      setIsExtracting(true);
      try {
        const response = await axios.post('/api/admin/publications/extract-outcomes', {
          pmid: localPublication.pmid,
          abstract: localPublication.abstract
        });
        setLocalPublication(response.data);
        onUpdate(response.data);
        toast.success(`Outcomes extracted for publication "${localPublication.title}"`);
      } catch (error) {
        console.error('Error extracting outcomes:', error);
      } finally {
        setIsExtracting(false);
      }
    };

    const handleClinicalTrialToggle = async (checked) => {
      try {
        const response = await axios.put(`/api/admin/publications/${localPublication.pmid}`, {
          isClinicalTrial: checked
        });
        setLocalPublication(prev => ({ ...prev, isClinicalTrial: checked }));
        onUpdate(response.data);
      } catch (error) {
        console.error('Error updating clinical trial status:', error);
      }
    };

    const handleModalOpen = () => setIsModalOpen(true);
    const handleModalClose = () => setIsModalOpen(false);
  
    return (
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" size="sm" onClick={handleModalOpen}>
            View Details
          </Button>
        </DialogTrigger>
        <DialogContent className="max-w-3xl max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold mb-4">{localPublication.title}</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div>
              <Label className="font-bold">Authors:</Label>
              <p>{localPublication.authors.join(', ')}</p>
            </div>
            <div>
              <Label className="font-bold">Journal:</Label>
              <p>{localPublication.journal}</p>
            </div>
            <div>
              <Label className="font-bold">Publication Date:</Label>
              <p>{new Date(localPublication.publicationDate).toLocaleDateString()}</p>
            </div>
            <div>
              <Label className="font-bold">DOI:</Label>
              <p>
                {localPublication.doi ? (
                  <a href={`https://doi.org/${localPublication.doi}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                    {localPublication.doi}
                  </a>
                ) : 'N/A'}
              </p>
            </div>
            <div>
              <Label className="font-bold">PMID:</Label>
              <p>{localPublication.pmid}</p>
            </div>
            <div>
              <Label className="font-bold">Abstract:</Label>
              <p>{renderAbstract(localPublication.abstract)}</p>
            </div>
            <div className="flex items-center space-x-2">
              <Switch
                id="clinical-trial"
                checked={localPublication.isClinicalTrial}
                onCheckedChange={handleClinicalTrialToggle}
              />
                {/* {console.log(localPublication)} */}
              <Label htmlFor="clinical-trial">Is Clinical Trial</Label>
            </div>
            {localPublication.isClinicalTrial && localPublication.clinicalTrialOutcomes && localPublication.clinicalTrialOutcomes.length > 0 && (
              <div>
                <Label className="font-bold">Clinical Trial Outcomes:</Label>
                <div className="space-y-4 mt-2">
                  {localPublication.clinicalTrialOutcomes.map((outcome, index) => (
                    <div key={index} className="p-4 bg-gray-100 rounded-lg">
                      <h4 className="font-semibold text-lg">{outcome.name} <span className="text-sm font-normal">({outcome.type})</span></h4>
                      <p><strong>Description:</strong> {outcome.description}</p>
                      <p><strong>Result:</strong> {outcome.result}</p>
                      <p><strong>Time Frame:</strong> {outcome.timeFrame}</p>
                      <p><strong>Measurement Details:</strong> {outcome.measurementDetails}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {localPublication.associatedDrugReviews && localPublication.associatedDrugReviews.length > 0 && (
              <div>
                <Label className="font-bold">Associated Drug Reviews:</Label>
                <p>{localPublication.associatedDrugReviews.map(review => review.brand_name).join(', ')}</p>
              </div>
            )}
            <div className="flex justify-end mt-4">
              <Button 
                onClick={handleExtractOutcomes} 
                disabled={isExtracting}
              >
                {isExtracting ? 'Extracting...' : 'Extract Outcomes'}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const PublicationCard = ({ publication, isSaved, onSave, onDelete, onUpdate }) => (
    <Card className="mb-4">
      <CardHeader>
        <h3 className="text-lg font-semibold">{publication.title}</h3>
        <p className="text-sm text-gray-600">{publication.authors.join(', ')}</p>
      </CardHeader>
      <CardContent>
        <p className="text-sm">{publication.journal}, {new Date(publication.publicationDate).getFullYear()}</p>
        <p className="text-sm mt-2">{renderAbstract(publication.abstract)}</p>
        {isSaved && publication.associatedDrugReviews && publication.associatedDrugReviews.length > 0 && (
          <p className="text-sm mt-2">
            <strong>Associated Drug Reviews:</strong> {publication.associatedDrugReviews.map(review => review.brand_name).join(', ')}
          </p>
        )}
      </CardContent>
      <CardFooter className="justify-between">
        {publication.doi && (
          <a href={`https://doi.org/${publication.doi}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
            <ExternalLink size={16} className="inline mr-1" />
            View on DOI
          </a>
        )}
        <div className="space-x-2">
          <PublicationDetailsModal publication={publication} onUpdate={onUpdate} />
          {isSaved ? (
            <Button variant="destructive" onClick={() => onDelete(publication.pmid)}>
              <Trash2 size={16} className="mr-1" /> Delete
            </Button>
          ) : (
            <Button variant="default" onClick={() => onSave(publication)}>
              <Save size={16} className="mr-1" /> Save
            </Button>
          )}
        </div>
      </CardFooter>
    </Card>
  );

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
        <Toaster position="top-right" />
      <h1 className="text-2xl font-semibold mb-6">Publications Management</h1>

      <form onSubmit={handleSearch} className="mb-6">
        <div className="flex">
          <Input
            type="text"
            placeholder="Search PubMed..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow"
          />
          <Button type="submit" className="ml-2">
            <Search size={20} />
          </Button>
        </div>
      </form>

      <Tabs defaultValue="search" className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="search">Search Results</TabsTrigger>
          <TabsTrigger value="saved">Saved Publications</TabsTrigger>
        </TabsList>
        <TabsContent value="search">
          {isLoading ? (
            <div className="text-center py-4">Loading...</div>
          ) : (
            searchResults.map((pub) => (
              <PublicationCard
                key={pub.pmid}
                publication={pub}
                isSaved={false}
                onSave={handleSave}
                onUpdate={handlePublicationUpdate}
              />
            ))
          )}
        </TabsContent>
        <TabsContent value="saved">
          {savedPublications.map((pub) => (
            <PublicationCard
              key={pub.pmid}
              publication={pub}
              isSaved={true}
              onDelete={handleDelete}
              onUpdate={handlePublicationUpdate}
            />
          ))}
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default AdminPublications;
