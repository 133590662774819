import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Search, Edit, ChevronLeft, ChevronRight, RefreshCw, X, ChevronDown, ChevronUp, Link } from 'lucide-react';
import { Toaster, toast } from 'react-hot-toast';

// EditModal component for editing drug reviews
const EditModal = ({ review, onClose, onSave, pcpaReviews, onAssociatePcpa }) => {
  const [editedReview, setEditedReview] = useState(review);
  const [expandedFields, setExpandedFields] = useState({});
  const [isUpdating, setIsUpdating] = useState({ clinical: false, recommendation: false });
  const [isExtractingCDEC, setIsExtractingCDEC] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedReview(prev => ({
      ...prev,
      [name]: name === 'is_rare_disease' || name === 'is_pcodr'
        ? value === 'true'
        : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(editedReview);
  };

  const toggleFieldExpansion = (fieldName) => {
    setExpandedFields(prev => ({ ...prev, [fieldName]: !prev[fieldName] }));
  };

  const triggerTextUpdate = async (type) => {
    setIsUpdating(prev => ({ ...prev, [type]: true }));
    try {
      let url = type === 'clinical'
        ? editedReview.clinical_review_report_url
        : editedReview.recommendation_and_reasons_report_url;

      url = url.replace(/^https?:\/\/(www\.)?cadth\.ca\//, '');
      const fullUrl = `https://www.cadth.ca/${url}`;

      const response = await axios.post('/api/report-builder/convert-pdf', {
        reportId: editedReview._id,
        pdfUrl: fullUrl,
        conversionType: type
      });

      pollConversionStatus(response.data.taskId, type);
    } catch (error) {
      console.error(`Error triggering ${type} text update:`, error);
      toast.error(`Failed to start ${type} text update. Please try again.`);
      setIsUpdating(prev => ({ ...prev, [type]: false }));
    }
  };

  const pollConversionStatus = async (taskId, type) => {
    try {
      const response = await axios.get(`/api/report-builder/conversion-status/${taskId}`);
      if (response.data.status === 'completed') {
        const updatedReview = await axios.put(`/api/report-builder/update-report/${editedReview._id}`, {
          taskId,
          conversionType: type
        });
        setEditedReview(updatedReview.data);
        setIsUpdating(prev => ({ ...prev, [type]: false }));
        toast.success(`${type === 'clinical' ? 'Clinical review' : 'Recommendation and reasons'} text updated successfully!`);
      } else {
        setTimeout(() => pollConversionStatus(taskId, type), 5000);
      }
    } catch (error) {
      console.error(`Error checking ${type} conversion status:`, error);
      setIsUpdating(prev => ({ ...prev, [type]: false }));
      toast.error(`Error updating ${type} text. Please try again.`);
    }
  };
  const handleExtractCDECMembers = async () => {
    setIsExtractingCDEC(true);
    try {
      const response = await axios.post(`/api/admin/drug-reviews/${editedReview._id}/extract-cdec-members`);
      const updatedReview = response.data;
      setEditedReview(updatedReview);
      toast.success(`CDEC Members extracted successfully! ${updatedReview.cdec_members.length} members added.`);
    } catch (error) {
      console.error('Error extracting CDEC members:', error);
      toast.error('Error extracting CDEC members. Please try again.');
    } finally {
      setIsExtractingCDEC(false);
    }
  };

  const renderCDECMembers = () => {
    return (
      <div className="flex flex-col mb-4">
        <div className="flex justify-between items-center mb-2">
          <label className="text-sm font-medium text-gray-700">CDEC Members</label>
          <div className="flex items-center">
            <button
              type="button"
              onClick={handleExtractCDECMembers}
              disabled={isExtractingCDEC}
              className="mr-2 px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition duration-200"
            >
              {isExtractingCDEC ? (
                <RefreshCw size={16} className="animate-spin" />
              ) : (
                'Extract CDEC Members'
              )}
            </button>
            <button
              type="button"
              onClick={() => toggleFieldExpansion('cdec_members')}
              className="text-blue-500 hover:text-blue-700"
            >
              {expandedFields['cdec_members'] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
          </div>
        </div>
        {expandedFields['cdec_members'] && (
          <div className="border rounded-md p-3 max-h-64 overflow-y-auto">
            {editedReview.cdec_members && editedReview.cdec_members.length > 0 ? (
              editedReview.cdec_members.map((member, index) => (
                <div key={index} className="mb-2">
                  <p className="font-medium">{member.name}</p>
                  <p className="text-sm text-gray-600">{member.role}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-600 italic">No CDEC members available</p>
            )}
          </div>
        )}
        {!expandedFields['cdec_members'] && (
          <div className="text-gray-600 italic">
            {editedReview.cdec_members && editedReview.cdec_members.length > 0
              ? `${editedReview.cdec_members.length} CDEC members available`
              : 'No CDEC members available'}
          </div>
        )}
      </div>
    );
  };


  const renderField = (key, value) => {
    if (key === '_id' || key === 'date_updated') return null;
    if (key === 'cdec_members') {
      return renderCDECMembers();
    }

    if (key === 'clinical_review_report_text' || key === 'recommendation_and_reasons_text') {
      const type = key === 'clinical_review_report_text' ? 'clinical' : 'recommendation';
      const url = type === 'clinical' ? editedReview.clinical_review_report_url : editedReview.recommendation_and_reasons_report_url;

      return (
        <div key={key} className="flex flex-col mb-4">
          <div className="flex justify-between items-center mb-2">
            <label className="text-sm font-medium text-gray-700">
              {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </label>
            <div className="flex items-center">
              {url && (
                <button
                  type="button"
                  onClick={() => triggerTextUpdate(type)}
                  disabled={isUpdating[type]}
                  className="mr-2 px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition duration-200"
                >
                  {isUpdating[type] ? (
                    <RefreshCw size={16} className="animate-spin" />
                  ) : (
                    'Update Text'
                  )}
                </button>
              )}
              <button
                type="button"
                onClick={() => toggleFieldExpansion(key)}
                className="text-blue-500 hover:text-blue-700"
              >
                {expandedFields[key] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </button>
            </div>
          </div>
          {expandedFields[key] && (
            <textarea
              name={key}
              value={value || ''}
              onChange={handleChange}
              className="border rounded-md px-3 py-2 h-64 resize-y"
            />
          )}
          {!expandedFields[key] && (
            <div className="text-gray-600 italic">
              {value ? `${value.substring(0, 100)}...` : 'No content'}
            </div>
          )}
        </div>
      );
    }

    if (key === 'clinical_review_report_pages' || key === 'recommendation_and_reasons_pages') {
      return (
        <div key={key} className="flex flex-col mb-4">
          <div className="flex justify-between items-center mb-2">
            <label className="text-sm font-medium text-gray-700">
              {key === 'clinical_review_report_pages' ? 'Clinical Review Report Pages' : 'Recommendation and Reasons Pages'}
            </label>
            <button
              type="button"
              onClick={() => toggleFieldExpansion(key)}
              className="text-blue-500 hover:text-blue-700"
            >
              {expandedFields[key] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
          </div>
          {expandedFields[key] && (
            <div className="border rounded-md p-3 max-h-64 overflow-y-auto">
              {value && value.length > 0 ? (
                [...value]
                  .sort((a, b) => a.page_number - b.page_number)
                  .map((page, index) => (
                    <div key={index} className="mb-4">
                      <h4 className="font-medium">Page {page.page_number}</h4>
                      <p className="text-sm text-gray-600">{page.content}</p>
                    </div>
                  ))
              ) : (
                <p className="text-gray-600 italic">No pages available</p>
              )}
            </div>
          )}
          {!expandedFields[key] && (
            <div className="text-gray-600 italic">
              {value && value.length > 0 ? `${value.length} pages available` : 'No pages available'}
            </div>
          )}
        </div>
      );
    }

    if (key === 'pcpa_review') {
      return (
        <div key={key} className="flex flex-col mb-4">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Associated pCPA Review
          </label>
          <select
            value={value?._id || ''}
            onChange={(e) => onAssociatePcpa(editedReview._id, e.target.value)}
            className="border rounded-md px-3 py-2"
          >
            <option value="">Select pCPA Review</option>
            {pcpaReviews.map((pcpaReview) => (
              <option key={pcpaReview._id} value={pcpaReview._id}>
                {pcpaReview.brand_name} - {pcpaReview.pcpa_file_number}
              </option>
            ))}
          </select>
        </div>
      );
    }

    if (key === 'is_rare_disease' || key === 'is_pcodr') {
      return (
        <div key={key} className="flex flex-col mb-4">
          <label className="text-sm font-medium text-gray-700 mb-1">
            {key === 'is_rare_disease' ? 'Is Rare Disease' : 'Is pCODR'}
          </label>
          <select
            name={key}
            value={value.toString()}
            onChange={handleChange}
            className="border rounded-md px-3 py-2"
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
      );
    }

    return (
      <div key={key} className="flex flex-col mb-4">
        <label className="text-sm font-medium text-gray-700 mb-1">
          {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
        </label>
        {key.includes('date') ? (
          <input
            type="date"
            name={key}
            value={value ? new Date(value).toISOString().split('T')[0] : ''}
            onChange={handleChange}
            className="border rounded-md px-3 py-2"
          />
        ) : (
          <input
            type="text"
            name={key}
            value={value || ''}
            onChange={handleChange}
            className="border rounded-md px-3 py-2"
          />
        )}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-8 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Edit Drug Review</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          {Object.entries(editedReview).map(([key, value]) => renderField(key, value))}
          <div className="flex justify-end space-x-2 mt-6">
            <button type="button" onClick={onClose} className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300">
              Cancel
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const PCPAAssociationModal = ({ review, onClose, onAssociate }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pcpaReviews, setPcpaReviews] = useState([]);
  const [selectedPcpaReview, setSelectedPcpaReview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedReviews, setExpandedReviews] = useState({});

  const searchPcpaReviews = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('/api/admin/pcpa-reviews-for-association', {
        params: { search: searchTerm }
      });
      console.log('Received pCPA reviews:', response.data); // Log the received data
      setPcpaReviews(response.data);
    } catch (error) {
      console.error('Error searching pCPA reviews:', error);
      toast.error('Error searching pCPA reviews. Please try again.');
    }
    setIsLoading(false);
  };

  const handleAssociate = () => {
    if (selectedPcpaReview) {
      onAssociate(review._id, selectedPcpaReview._id);
    }
  };

  const toggleExpand = (id) => {
    setExpandedReviews(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const renderPcpaReviewDetails = (pcpaReview) => {
    const isExpanded = expandedReviews[pcpaReview._id];
    return (
      <div key={pcpaReview._id} className="border-b border-gray-200 last:border-b-0">
        <div
          className={`p-4 cursor-pointer hover:bg-gray-100 ${selectedPcpaReview?._id === pcpaReview._id ? 'bg-blue-100' : ''}`}
          onClick={() => setSelectedPcpaReview(pcpaReview)}
        >
          <div className="flex justify-between items-center">
            <div>
              <h3 className="font-bold">{pcpaReview.brand_name || 'N/A'} - {pcpaReview.generic_name || 'N/A'}</h3>
              <p className="text-sm text-gray-600">pCPA File Number: {pcpaReview.pcpa_file_number || 'N/A'}</p>
            </div>
            <button onClick={(e) => { e.stopPropagation(); toggleExpand(pcpaReview._id); }}>
              {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
          </div>
          {isExpanded && (
            <div className="mt-4 text-sm">
              <p><strong>Manufacturer:</strong> {pcpaReview.manufacturer || 'N/A'}</p>
              <p><strong>Status:</strong> {pcpaReview.status || 'N/A'}</p>
              <p><strong>Indication:</strong> {pcpaReview.indication || 'N/A'}</p>
              <p><strong>CADTH Project Number:</strong> {pcpaReview.cadth_project_number || 'N/A'}</p>
              <p><strong>pCPA Engagement Letter:</strong> {pcpaReview.pcpa_engagement_letter || 'N/A'}</p>
              <p><strong>Negotiation Process Concluded:</strong> {pcpaReview.negotiation_process_concluded || 'N/A'}</p>
              <p><strong>Engagement Date:</strong> {pcpaReview.engagement_date ? new Date(pcpaReview.engagement_date).toLocaleDateString() : 'N/A'}</p>
              <p><strong>Conclusion Date:</strong> {pcpaReview.conclusion_date ? new Date(pcpaReview.conclusion_date).toLocaleDateString() : 'N/A'}</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-8 max-w-3xl w-full max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Associate pCPA Review</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="mb-4 flex">
          <input
            type="text"
            placeholder="Search pCPA reviews..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={searchPcpaReviews}
            className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-200"
          >
            <Search size={20} />
          </button>
        </div>
        {isLoading ? (
          <div className="text-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
          </div>
        ) : (
          <div className="flex-grow overflow-y-auto border border-gray-200 rounded-md">
            {pcpaReviews.map(renderPcpaReviewDetails)}
          </div>
        )}
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={handleAssociate}
            disabled={!selectedPcpaReview}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            Associate
          </button>
        </div>
      </div>
    </div>
  );
};


const AdminDrugReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [totalReviews, setTotalReviews] = useState(0);
  const [isCrawling, setIsCrawling] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [pcpaReviews, setPcpaReviews] = useState([]);
  const [showPcpaAssociationModal, setShowPcpaAssociationModal] = useState(false);
  const [isPcodrFilter, setIsPcodrFilter] = useState(false);

  const fetchReviews = async (page = 1, search = '') => {
    setIsLoading(true);
    try {
      const response = await axios.get('/api/admin/drug-reviews', {
        params: { page, limit: 5, search, isPcodr: isPcodrFilter }
      });
      setReviews(response.data.reviews);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
      setTotalReviews(response.data.totalReviews);
    } catch (error) {
      console.error('Error fetching reviews:', error);
      setReviews([]);
    }
    setIsLoading(false);
  };

  const fetchPcpaReviews = async () => {
    try {
      const response = await axios.get('/api/admin/pcpa-reviews');
      setPcpaReviews(response.data.reviews);
    } catch (error) {
      console.error('Error fetching pCPA reviews:', error);
    }
  };

  useEffect(() => {
    fetchReviews(1, '');
    fetchPcpaReviews();
  }, [isPcodrFilter]);

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchReviews(1, searchTerm);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchReviews(newPage, searchTerm);
  };

  const triggerCrawler = async () => {
    setIsCrawling(true);
    try {
      await axios.post('/api/crawl-cadth');
      toast.success('Crawler completed successfully!');
      fetchReviews(1, searchTerm);
    } catch (error) {
      console.error('Error triggering crawler:', error);
      toast.error('Error triggering crawler. Please try again.');
    } finally {
      setIsCrawling(false);
    }
  };

  const handleRowClick = (review) => {
    setSelectedReview(review);
  };

  const handleCloseModal = () => {
    setSelectedReview(null);
  };

  const handleSaveReview = async (editedReview) => {
    try {
      await axios.put(`/api/admin/drug-reviews/${editedReview._id}`, editedReview);
      setReviews(reviews.map(review => review._id === editedReview._id ? editedReview : review));
      setSelectedReview(null);
      toast.success('Review updated successfully!');
    } catch (error) {
      console.error('Error updating review:', error);
      toast.error('Error updating review. Please try again.');
    }
  };

  const handleAssociatePcpa = async (drugReviewId, pcpaReviewId) => {
    try {
      const response = await axios.post(`/api/admin/drug-reviews/${drugReviewId}/associate-pcpa`, { pcpaReviewId });
      setReviews(reviews.map(review => review._id === drugReviewId ? response.data : review));
      setSelectedReview(response.data);
      setShowPcpaAssociationModal(false);
    } catch (error) {
      console.error('Error associating pCPA review:', error);
      toast.error('Error associating pCPA review. Please try again.');
    }
  };

  const handleClassifyDisease = async (reviewId) => {
    try {
      const response = await axios.post(`/api/admin/drug-reviews/${reviewId}/classify-disease`);
      setReviews(reviews.map(review => review._id === reviewId ? response.data : review));
      toast.success(`Disease classification completed successfully! The ${response.data.therapeutic_area} is ${response.data.is_rare_disease ? 'a rare disease' : 'not a rare disease'}.`);

    } catch (error) {
      console.error('Error classifying disease:', error);
      toast.error('Error classifying disease. Please try again.');
    }
  };

  const handleExtractCDECMembers = async (reviewId) => {
    try {
      const response = await axios.post(`/api/admin/drug-reviews/${reviewId}/extract-cdec-members`);
      const updatedReview = response.data;
      setReviews(reviews.map(review => review._id === updatedReview._id ? updatedReview : review));
      setSelectedReview(updatedReview);
      toast.success(`CDEC Members Added Successfully! ${updatedReview.cdec_members.length} members added. ${updatedReview.cdec_members.map(member => member.name).join(', ')}`);
    } catch (error) {
      console.error('Error extracting CDEC members:', error);
      toast.error(`Error extracting CDEC members. ${error.response.data.message}`);
      // Add error handling here (e.g., display an error message to the user)
    }
  };

  const handlePcodrFilterChange = (e) => {
    setIsPcodrFilter(e.target.checked);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <Toaster position="top-right" />
      <h1 className="text-2xl font-semibold mb-6">Drug Reviews</h1>

      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="pcodrFilter"
            checked={isPcodrFilter}
            onChange={handlePcodrFilterChange}
            className="mr-2"
          />
          <label htmlFor="pcodrFilter" className="text-sm text-gray-600 mr-4">
            Show only pCODR drugs
          </label>
        </div>

        <form onSubmit={handleSearch} className="flex-grow mr-4">
          <div className="flex">
            <input
              type="text"
              placeholder="Search reviews..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-200">
              <Search size={20} />
            </button>
          </div>
        </form>

        <button
          onClick={triggerCrawler}
          disabled={isCrawling}
          className={`bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-200 ${isCrawling ? 'opacity-50 cursor-not-allowed' : ''
            }`}
        >
          {isCrawling ? (
            <RefreshCw size={20} className="animate-spin" />
          ) : (
            'Run Crawler'
          )}
        </button>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      ) : reviews.length === 0 ? (
        <div className="text-center py-4">No reviews found.</div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-3 px-4 text-left">Brand Name</th>
                  <th className="py-3 px-4 text-left">Generic Name</th>
                  <th className="py-3 px-4 text-left">Therapeutic Area</th>
                  <th className="py-3 px-4 text-left">Status</th>
                  <th className="py-3 px-4 text-left">Recommendation</th>
                  <th className="py-3 px-4 text-left">Date Submission Received</th>
                  <th className="py-3 px-4 text-left">Date Recommendation Issued</th>
                  <th className="py-3 px-4 text-left">Review Duration (Days)</th>
                  <th className="py-3 px-4 text-left">pCPA Association</th>
                  <th className="py-3 px-4 text-left">Date Last Crawled</th>
                  <th className="py-3 px-4 text-left">Rare Disease</th>
                  <th className="py-3 px-4 text-left">Is pCODR</th>
                  <th className="py-3 px-4 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {reviews.map((review) => (
                  <tr
                    key={review._id}
                    className="border-b border-gray-200 hover:bg-gray-50"
                  >
                    <td className="py-3 px-4">{review.brand_name}</td>
                    <td className="py-3 px-4">{review.generic_name}</td>
                    <td className="py-3 px-4">{review.therapeutic_area}</td>
                    <td className="py-3 px-4">{review.project_status}</td>
                    <td className="py-3 px-4">{review.recommendation_type}</td>
                    <td className="py-3 px-4">
                      {new Date(review.date_submission_received).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                    </td>
                    <td className="py-3 px-4">
                      {new Date(review.date_recommendation_issued).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                    </td>
                    <td className="py-3 px-4">
                      {(() => {
                        const daysDifference = Math.floor((new Date(review.date_recommendation_issued) - new Date(review.date_submission_received)) / (1000 * 60 * 60 * 24));
                        return daysDifference >= 0 ? (
                          daysDifference
                        ) : (
                          <div className="bg-red-500 text-white px-2 py-1 rounded">
                            Error
                          </div>
                        );
                      })()}
                    </td>
                    <td className="py-3 px-4">
                      {review.pcpa_review ? (
                        <span className="text-green-500">
                          <Link size={18} className="inline-block mr-2" />
                          {review.pcpa_review.pcpa_file_number}
                        </span>
                      ) : (
                        <button
                          onClick={() => {
                            setSelectedReview(review);
                            setShowPcpaAssociationModal(true);
                          }}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          Associate pCPA Review
                        </button>
                      )}
                    </td>
                    <td className="py-3 px-4">{new Date(review.date_updated).toLocaleString()}</td>
                    <td className="py-3 px-4">
                      {review.is_rare_disease ? 'Yes' : 'No'}
                    </td>
                    <td className="py-3 px-4">
                      {review.is_pcodr ? 'Yes' : 'No'}
                    </td>
                    <td className="py-3 px-4 flex justify-center items-center">
                      <button
                        onClick={() => handleRowClick(review)}
                        className="p-2 bg-gray-200 hover:bg-gray-300 text-blue-500 hover:text-blue-700 rounded-full shadow-md transition duration-150 ease-in-out">
                        <Edit size={18} />
                      </button>
                      <button
                        onClick={() => handleClassifyDisease(review._id)}
                        className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 transition-colors duration-200 ease-in-out m-1">
                        Classify
                      </button>
                      <button
                        onClick={() => handleExtractCDECMembers(review._id)}
                        className="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded-lg shadow-md transition-colors duration-200 ease-in-out m-1">
                        Extract CDEC
                      </button>
                    </td>
                    {/* <td className="py-3 px-4">
                      <button onClick={() => handleRowClick(review)} className="text-blue-500 hover:text-blue-700 mr-2">
                        <Edit size={18} />
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-6 flex justify-between items-center">
            <span>
              Showing {reviews.length} of {totalReviews} reviews | Page {currentPage} of {totalPages}
            </span>
            <div className="flex space-x-2">
              <button
                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition duration-200"
              >
                <ChevronLeft size={20} />
              </button>
              <button
                onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition duration-200"
              >
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </>
      )}

      {selectedReview && showPcpaAssociationModal && (
        <PCPAAssociationModal
          review={selectedReview}
          onClose={() => setShowPcpaAssociationModal(false)}
          onAssociate={handleAssociatePcpa}
        />
      )}

      {selectedReview && !showPcpaAssociationModal && (
        <EditModal
          review={selectedReview}
          onClose={handleCloseModal}
          onSave={handleSaveReview}
          pcpaReviews={pcpaReviews}
          onAssociatePcpa={handleAssociatePcpa}
        />
      )}

      
    </div>
  );
};

export default AdminDrugReviews;