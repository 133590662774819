import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Search, Edit, Trash2, AlertTriangle, ChevronRight, ChevronLeft, X, ExternalLink, Upload } from 'lucide-react';
import { Dialog, Transition } from '@headlessui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Toaster, toast } from 'react-hot-toast';

const CompanyDataAdmin = () => {
    const [companies, setCompanies] = useState([]);
    const [searchParams, setSearchParams] = useState({ search: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [processingPdf, setProcessingPdf] = useState(false);

    useEffect(() => {
        fetchCompanies();
    }, [currentPage, searchParams]);

    const fetchCompanies = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get('/api/admin/companies', {
                params: { ...searchParams, page: currentPage, limit: 10 }
            });
            setCompanies(response.data.companies);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setError('Failed to fetch companies. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        fetchCompanies();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prevParams => ({ ...prevParams, [name]: value }));
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleCompanySelect = useCallback(async (id) => {
        try {
            const response = await axios.get(`/api/admin/companies/${id}`);
            setSelectedCompany(response.data);
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error fetching company details:', error);
            toast.error(error.response?.data?.error || 'Failed to fetch company details. Please try again.');
            setError('Failed to fetch company details. Please try again.');
        }
    }, []);

    const handleCompanyUpdate = useCallback(async (updatedCompany) => {
        try {
            const response = await axios.put(`/api/admin/companies/${updatedCompany._id}`, updatedCompany);
            setSelectedCompany(response.data);
            setCompanies(prevCompanies =>
                prevCompanies.map(company =>
                    company._id === updatedCompany._id ? response.data : company
                )
            );
        } catch (error) {
            console.error('Error updating company:', error);
            setError('Failed to update company. Please try again.');
        }
    }, []);

    const handleCompanyDelete = useCallback(async (id) => {
        if (window.confirm('Are you sure you want to delete this company?')) {
            try {
                await axios.delete(`/api/admin/companies/${id}`);
                fetchCompanies();
                setSelectedCompany(null);
                setIsModalOpen(false);
            } catch (error) {
                console.error('Error deleting company:', error);
                setError('Failed to delete company. Please try again.');
            }
        }
    }, [fetchCompanies]);

    const handlePdfUrlChange = useCallback((event) => {
        setPdfUrl(event.target.value);
    }, []);

    const handleProcessPdf = useCallback(async () => {
        if (!pdfUrl || !selectedCompany) return;

        setProcessingPdf(true);
        setError(null);

        try {
            const response = await axios.post(`/api/admin/companies/${selectedCompany._id}/process-pdf`, {
                pdfUrl: pdfUrl
            });

            setSelectedCompany(prevCompany => ({
                ...prevCompany,
                ...response.data.company
            }));

            setCompanies(prevCompanies =>
                prevCompanies.map(company =>
                    company._id === selectedCompany._id ? { ...company, ...response.data.company } : company
                )
            );

            setPdfUrl('');
            toast.success('PDF processed and insights updated successfully');
        } catch (error) {
            console.error('Error processing PDF:', error);
            setError(error.response?.data?.error || 'An error occurred while processing the PDF');
        } finally {
            setProcessingPdf(false);
        }
    }, [pdfUrl, selectedCompany]);

    const MemoizedCompanyModal = useMemo(() => {
        return React.memo(({ company, isOpen, onClose, onUpdate, onProcessPdf, pdfUrl, onPdfUrlChange, processingPdf, error }) => {
            const [trialSearchTerm, setTrialSearchTerm] = useState('');
            const [trialPage, setTrialPage] = useState(1);
            const trialsPerPage = 5;

            const filteredTrials = useMemo(() => {
                if (!company?.clinicalTrials) return [];
                return company.clinicalTrials.filter(trial =>
                    trial.briefTitle?.toLowerCase().includes(trialSearchTerm.toLowerCase()) ||
                    trial.nctId?.toLowerCase().includes(trialSearchTerm.toLowerCase()) ||
                    trial.condition?.toLowerCase().includes(trialSearchTerm.toLowerCase()) ||
                    trial.interventionName?.toLowerCase().includes(trialSearchTerm.toLowerCase())
                );
            }, [company, trialSearchTerm]);

            const totalTrialPages = Math.ceil(filteredTrials.length / trialsPerPage);

            const paginatedTrials = useMemo(() => {
                return filteredTrials.slice(
                    (trialPage - 1) * trialsPerPage,
                    trialPage * trialsPerPage
                );
            }, [filteredTrials, trialPage]);

            const handleTrialSearch = useCallback((e) => {
                setTrialSearchTerm(e.target.value);
                setTrialPage(1);
            }, []);

            const handleTrialPageChange = useCallback((newPage) => {
                setTrialPage(newPage);
            }, []);

            if (!company) return null;

            return (
                <Transition appear show={isOpen} as={React.Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={onClose}>
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={React.Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
                                        >
                                            {company.name}
                                            <button
                                                onClick={onClose}
                                                className="text-gray-400 hover:text-gray-500"
                                            >
                                                <X size={24} />
                                            </button>
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Last Updated: {new Date(company.lastUpdated).toLocaleDateString()}
                                            </p>
                                        </div>
                                        <div className="mt-4">
                                            <h4 className="text-md font-medium text-gray-900">Pipeline Insights</h4>
                                            <div className="text-sm text-gray-500 mt-1 prose max-w-full">
                                                <ReactMarkdown
                                                    remarkPlugins={[remarkGfm]}
                                                    components={{
                                                        table: ({ node, ...props }) => (
                                                            <div className="overflow-x-auto my-4">
                                                                <table className="min-w-full divide-y divide-gray-200" {...props} />
                                                            </div>
                                                        ),
                                                        thead: ({ node, ...props }) => <thead className="bg-gray-50" {...props} />,
                                                        th: ({ node, ...props }) => <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" {...props} />,
                                                        td: ({ node, ...props }) => <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" {...props} />
                                                    }}
                                                >
                                                    {company.pipelineInsights || "No insights available"}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <h4 className="text-md font-medium text-gray-900">Process PDF Document</h4>
                                            <input
                                                type="text"
                                                placeholder="Enter PDF URL"
                                                value={pdfUrl}
                                                onChange={onPdfUrlChange}
                                                className="mt-2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                            />
                                            <button
                                                onClick={onProcessPdf}
                                                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
                                                disabled={!pdfUrl || processingPdf}
                                            >
                                                {processingPdf ? (
                                                    <span className="flex items-center">
                                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        Processing...
                                                    </span>
                                                ) : (
                                                    <>
                                                        <Upload className="inline-block mr-2" size={20} />
                                                        Process PDF
                                                    </>
                                                )}
                                            </button>
                                            {error && (
                                                <div className="mt-2 text-red-500">
                                                    <AlertTriangle className="inline-block mr-2" size={20} />
                                                    {error}
                                                </div>
                                            )}
                                        </div>
                                        <div className="mt-4">
                                            <h4 className="text-md font-medium text-gray-900">Clinical Trials</h4>
                                            {company.clinicalTrials && company.clinicalTrials.length > 0 ? (
                                                <>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            placeholder="Search trials..."
                                                            value={trialSearchTerm}
                                                            onChange={handleTrialSearch}
                                                            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                                        />
                                                    </div>
                                                    <div className="mt-2 overflow-x-auto">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-50">
                                                                <tr>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">NCT ID</th>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Condition</th>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Intervention</th>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phase</th>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Date</th>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Completion Date</th>
                                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Enrollment</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                                {paginatedTrials.map((trial) => (
                                                                    <tr key={trial.nctId}>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{trial.nctId}</td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{trial.briefTitle}</td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{trial.condition}</td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{trial.interventionName}</td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{trial.phase}</td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{trial.overallStatus}</td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{trial.startDate}</td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{trial.completionDate}</td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{trial.enrollmentCount}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="mt-4 flex justify-between items-center">
                                                        <button
                                                            onClick={() => handleTrialPageChange(Math.max(trialPage - 1, 1))}
                                                            disabled={trialPage === 1}
                                                            className="px-3 py-1 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
                                                        >
                                                            <ChevronLeft size={20} />
                                                        </button>
                                                        <span>Page {trialPage} of {totalTrialPages}</span>
                                                        <button
                                                            onClick={() => handleTrialPageChange(Math.min(trialPage + 1, totalTrialPages))}
                                                            disabled={trialPage === totalTrialPages}
                                                            className="px-3 py-1 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
                                                        >
                                                            <ChevronRight size={20} />
                                                        </button>
                                                    </div>
                                                </>
                                            ) : (
                                                <p className="mt-2 text-gray-500">No clinical trials available for this company.</p>
                                            )}
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            );
        });
    }, []);

    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <h1 className="text-2xl font-semibold mb-6">Company Data Management</h1>

            <form onSubmit={handleSearch} className="mb-6">
                <div className="flex">
                    <input
                        type="text"
                        name="search"
                        placeholder="Search companies..."
                        value={searchParams.search}
                        onChange={handleInputChange}
                        className="px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 flex-grow"
                    />
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-200">
                        <Search className="inline-block" size={20} />
                    </button>
                </div>
            </form>

            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <strong className="font-bold">Error: </strong>
                    <span className="block sm:inline">{error}</span>
                    <AlertTriangle className="inline-block ml-2" size={20} />
                </div>
            )}

            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
            ) : (
                <div>
                    <h2 className="text-xl font-semibold mb-4">Companies</h2>
                    <ul className="divide-y divide-gray-200">
                        {companies.map((company) => (
                            <li key={company._id} className="py-4 flex justify-between items-center">
                                <button
                                    onClick={() => handleCompanySelect(company._id)}
                                    className="text-left hover:text-blue-500 flex items-center"
                                >
                                    {company.name}
                                    <ExternalLink size={16} className="ml-2" />
                                </button>
                                <button
                                    onClick={() => handleCompanyDelete(company._id)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    <Trash2 size={18} />
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className="mt-4 flex justify-between items-center">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="px-3 py-1 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
                        >
                            <ChevronLeft size={20} />
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="px-3 py-1 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
                        >
                            <ChevronRight size={20} />
                        </button>
                    </div>
                </div>
            )}

            <MemoizedCompanyModal
                company={selectedCompany}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                // onUpdate={handleCompanyUpdate}
                onProcessPdf={handleProcessPdf}
                pdfUrl={pdfUrl}
                onPdfUrlChange={handlePdfUrlChange}
                processingPdf={processingPdf}
                error={error}
            />
        </div>
    );
};

export default CompanyDataAdmin;