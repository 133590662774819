import React, { useState, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { Search, Loader, ExternalLink } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useAuth } from '../contexts/AuthContext'; // You'll need to create this context

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleDateString('en-CA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const SearchPage = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, loading } = useAuth();
  

  const handleSearch = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await axios.get('/api/search-cadth-reports', {
        params: { query, page, limit: 10 }
      });
      setResults(response.data.results);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Search error:', error);
    }
    setIsLoading(false);
  };


  const chartData = useMemo(() => {
    const yearCounts = results.reduce((acc, result) => {
      const year = new Date(result.date_recommendation_issued).getFullYear();
      acc[year] = (acc[year] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(yearCounts)
      .map(([year, count]) => ({ year, count }))
      .sort((a, b) => a.year - b.year);
  }, [results]);

  if (loading) {  
    
    return <div>Loading...</div>; // Or any loading component
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }


  return (
    <div className="container mx-auto p-4 max-w-6xl">
      <h1 className="text-3xl font-bold mb-8 text-center text-blue-600">Drug Review Search</h1>
      <div className="mb-8 flex items-center justify-center">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter drug name, generic name, or therapeutic area..."
          className="w-full max-w-xl px-4 py-2 rounded-l-full border-2 border-blue-300 focus:outline-none focus:border-blue-500 transition duration-300"
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
        />
        <button
          onClick={() => handleSearch()}
          className="bg-blue-500 text-white px-6 py-2 rounded-r-full hover:bg-blue-600 transition duration-300 flex items-center"
        >
          <Search size={20} className="mr-2" />
          Search
        </button>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Loader className="animate-spin text-blue-500" size={40} />
        </div>
      ) : (
        <>
          {results.length > 0 && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-4 text-center text-blue-600">Drug Reviews by Year</h2>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis allowDecimals={false} />
                  <Tooltip />
                  <Bar dataKey="count" fill="#3B82F6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
          <div className="grid gap-6 mb-8">
            {results.map((result) => (
              <div key={result._id} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                <div className="flex justify-between items-start mb-4">
                  <h2 className="text-2xl font-bold text-blue-600">{result.brand_name}</h2>
                  <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
                    {result.is_pcodr ? 'pCODR' : 'CDR'}
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <p><span className="font-semibold">Generic Name:</span> {result.generic_name}</p>
                  <p><span className="font-semibold">Therapeutic Area:</span> {result.therapeutic_area}</p>
                  <p><span className="font-semibold">Manufacturer:</span> {result.manufacturer_name}</p>
                  <p><span className="font-semibold">Project Status:</span> {result.project_status}</p>
                  <p><span className="font-semibold">Recommendation Type:</span> {result.recommendation_type}</p>
                  <p><span className="font-semibold">Date Submitted:</span> {formatDate(result.date_submission_received)}</p>
                  <p><span className="font-semibold">Date Recommended:</span> {formatDate(result.date_recommendation_issued)}</p>
                  <p><span className="font-semibold">Date Created:</span> {formatDate(result.date_created)}</p>
                  <p><span className="font-semibold">Date Updated:</span> {formatDate(result.date_updated)}</p>
                </div>
                <div className="flex flex-wrap gap-2">
                  <a href={result.drug_review_url} target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-500 hover:text-blue-700">
                    Drug Review <ExternalLink size={16} className="ml-1" />
                  </a>
                  {result.recommendation_and_reasons_report_url && (
                    <a href={`https://www.cadth.ca${result.recommendation_and_reasons_report_url}`} target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-500 hover:text-blue-700">
                      Recommendation Report <ExternalLink size={16} className="ml-1" />
                    </a>
                  )}
                  {result.clinical_review_report_url && (
                    <a href={`https://www.cadth.ca${result.clinical_review_report_url}`} target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-500 hover:text-blue-700">
                      Clinical Review Report <ExternalLink size={16} className="ml-1" />
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
          {totalPages > 1 && (
            <div className="flex justify-center mt-8">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => handleSearch(page)}
                  className={`mx-1 px-4 py-2 rounded-full ${
                    currentPage === page
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  } transition duration-300`}
                >
                  {page}
                </button>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SearchPage;