import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PCPAReviewPage = () => {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get('/api/pcpa-reviews');
        setReviews(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching pCPA reviews:', error);
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <h1>pCPA Reviews</h1>
      {reviews.map(review => (
        <div key={review._id}>
          <h2>{review.brand_name}</h2>
          <p>Generic Name: {review.generic_name}</p>
          <p>Status: {review.status}</p>
          {/* Add more fields as needed */}
        </div>
      ))}
    </div>
  );
};

export default PCPAReviewPage;