import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Search, Edit, Trash2, ChevronLeft, ChevronRight, RefreshCw, X, ChevronDown, ChevronUp } from 'lucide-react';
import { Toaster, toast } from 'react-hot-toast';

// EditModal component for editing pCPA reviews
const EditModal = ({ review, onClose, onSave }) => {
  const [editedReview, setEditedReview] = useState(review);
  const [expandedFields, setExpandedFields] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedReview(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(editedReview);
  };

  const toggleFieldExpansion = (fieldName) => {
    setExpandedFields(prev => ({ ...prev, [fieldName]: !prev[fieldName] }));
  };

  const renderField = (key, value) => {
    if (key === '_id' || key === 'createdAt' || key === 'updatedAt') return null;

    if (typeof value === 'boolean') {
      return (
        <div key={key} className="flex flex-col mb-4">
          <label className="text-sm font-medium text-gray-700 mb-1">
            {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          </label>
          <select
            name={key}
            value={value}
            onChange={handleChange}
            className="border rounded-md px-3 py-2"
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </div>
      );
    }

    if (key.includes('date')) {
      return (
        <div key={key} className="flex flex-col mb-4">
          <label className="text-sm font-medium text-gray-700 mb-1">
            {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          </label>
          <input
            type="date"
            name={key}
            value={value ? new Date(value).toISOString().split('T')[0] : ''}
            onChange={handleChange}
            className="border rounded-md px-3 py-2"
          />
        </div>
      );
    }

    if (typeof value === 'string' && value.length > 100) {
      return (
        <div key={key} className="flex flex-col mb-4">
          <div className="flex justify-between items-center mb-2">
            <label className="text-sm font-medium text-gray-700">
              {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </label>
            <button
              type="button"
              onClick={() => toggleFieldExpansion(key)}
              className="text-blue-500 hover:text-blue-700"
            >
              {expandedFields[key] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
          </div>
          {expandedFields[key] ? (
            <textarea
              name={key}
              value={value || ''}
              onChange={handleChange}
              className="border rounded-md px-3 py-2 h-32 resize-y"
            />
          ) : (
            <div className="text-gray-600 italic">
              {value ? `${value.substring(0, 100)}...` : 'No content'}
            </div>
          )}
        </div>
      );
    }

    return (
      <div key={key} className="flex flex-col mb-4">
        <label className="text-sm font-medium text-gray-700 mb-1">
          {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
        </label>
        <input
          type="text"
          name={key}
          value={value || ''}
          onChange={handleChange}
          className="border rounded-md px-3 py-2"
        />
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-8 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Edit pCPA Review</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          {Object.entries(editedReview).map(([key, value]) => renderField(key, value))}
          <div className="flex justify-end space-x-2 mt-6">
            <button type="button" onClick={onClose} className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300">
              Cancel
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const AdminPCPAReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isCrawling, setIsCrawling] = useState(false);
  const [editingReview, setEditingReview] = useState(null);

  const fetchReviews = async (page = 1, search = '') => {
    setIsLoading(true);
    try {
      const response = await axios.get('/api/admin/pcpa-reviews', {
        params: { page, limit: 10, search }
      });
      setReviews(response.data.reviews);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchReviews(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchReviews(1, searchTerm);
  };

  const handleEdit = (review) => {
    setEditingReview(review);
  };

  const handleSave = async (updatedReview) => {
    try {
      await axios.put(`/api/admin/pcpa-reviews/${updatedReview._id}`, updatedReview);
      setEditingReview(null);
      fetchReviews(currentPage, searchTerm);
    } catch (error) {
      console.error('Error updating review:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this review?')) {
      try {
        await axios.delete(`/api/admin/pcpa-reviews/${id}`);
        fetchReviews(currentPage, searchTerm);
      } catch (error) {
        console.error('Error deleting review:', error);
      }
    }
  };

  const triggerCrawler = async () => {
    setIsCrawling(true);
    try {
      await axios.post('/api/crawl-pcpa');
      toast.success('Crawler completed successfully!');
      fetchReviews(currentPage, searchTerm);
    } catch (error) {
      console.error('Error triggering crawler:', error);
      toast.error('Error triggering crawler. Please try again.');
    } finally {
      setIsCrawling(false);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <Toaster position="top-right" />
      <h1 className="text-2xl font-semibold mb-6">Manage pCPA Reviews</h1>

      <div className="flex justify-between items-center mb-6">
        <form onSubmit={handleSearch} className="flex-grow mr-4">
          <div className="flex">
            <input
              type="text"
              placeholder="Search reviews..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-200">
              <Search size={20} />
            </button>
          </div>
        </form>

        <button
          onClick={triggerCrawler}
          disabled={isCrawling}
          className={`bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-200 ${isCrawling ? 'opacity-50 cursor-not-allowed' : ''
            }`}
        >
          {isCrawling ? (
            <RefreshCw size={20} className="animate-spin" />
          ) : (
            'Run Crawler'
          )}
        </button>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-3 px-4 text-left">Brand Name</th>
                  <th className="py-3 px-4 text-left">Generic Name</th>
                  <th className="py-3 px-4 text-left">Status</th>
                  <th className="py-3 px-4 text-left">Indication</th>
                  <th className="py-3 px-4 text-left">CADTH Project Number</th>
                  <th className="py-3 px-4 text-left">pCPA File Number</th>
                  <th className="py-3 px-4 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {reviews.map((review) => (
                  <tr key={review._id} className="border-b border-gray-200 hover:bg-gray-50">
                    <td className="py-3 px-4">{review.brand_name}</td>
                    <td className="py-3 px-4">{review.generic_name}</td>
                    <td className="py-3 px-4">{review.status}</td>
                    <td className="py-3 px-4">{review.indication}</td>
                    <td className="py-3 px-4">{review.cadth_project_number}</td>
                    <td className="py-3 px-4">{review.pcpa_file_number}</td>
                    <td className="py-3 px-4">
                      <button onClick={() => handleEdit(review)} className="text-blue-500 hover:text-blue-700 mr-2">
                        <Edit size={18} />
                      </button>
                      <button onClick={() => handleDelete(review._id)} className="text-red-500 hover:text-red-700">
                        <Trash2 size={18} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-6 flex justify-between items-center">
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <div className="flex space-x-2">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition duration-200"
              >
                <ChevronLeft size={20} />
              </button>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition duration-200"
              >
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </>
      )}

      {editingReview && (
        <EditModal
          review={editingReview}
          onClose={() => setEditingReview(null)}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default AdminPCPAReviews;