import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Search, Save, Trash2, AlertTriangle, ChevronRight, ChevronLeft } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


const GanttChart = ({ data }) => {
    const colors = ['#34D399', '#60A5FA', '#F87171', '#FBBF24', '#A78BFA', '#34D399', '#60A5FA', '#F87171'];

    const chartData = data.map((trial, index) => ({
        name: trial.briefTitle,
        start: new Date(trial.startDate).getTime(),
        end: new Date(trial.completionDate).getTime(),
        color: colors[index % colors.length],
    })).sort((a, b) => a.start - b.start);

    const minDate = Math.min(...chartData.map(d => d.start));
    const maxDate = Math.max(...chartData.map(d => d.end));

    const formatXAxis = (tickItem) => {
        const date = new Date(tickItem);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div className="bg-white p-2 border border-gray-300 rounded shadow">
                    <p className="font-bold">{data.name}</p>
                    <p>Start: {new Date(data.start).toLocaleDateString()}</p>
                    <p>End: {new Date(data.end).toLocaleDateString()}</p>
                </div>
            );
        }
        return null;
    };

    const truncateLabel = (label, maxLength = 30) => {
        return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
    };

    return (
        <ResponsiveContainer width="100%" height={chartData.length * 50 + 100}>
            <BarChart
                layout="vertical"
                data={chartData}
                margin={{ top: 20, right: 30, left: 250, bottom: 20 }}
            >
                <XAxis
                    type="number"
                    domain={[minDate, maxDate]}
                    tickFormatter={formatXAxis}
                    tickCount={6}
                />
                <YAxis
                    type="category"
                    dataKey="name"
                    width={240}
                    tick={(props) => (
                        <text
                            {...props}
                            fill="#333"
                            fontSize={12}
                            textAnchor="end"
                            dy={4}
                        >
                            {truncateLabel(props.payload.value)}
                        </text>
                    )}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="end" stackId="a" fill="transparent">
                    {chartData.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={entry.color}
                        />
                    ))}
                </Bar>
                <Bar dataKey="start" stackId="a" fill="transparent" />
            </BarChart>
        </ResponsiveContainer>
    );
};

const PipelineMonitor = () => {
    const [searchParams, setSearchParams] = useState({ manufacturerName: '', diseaseDescription: '', drugName: '' });
    const [searchResults, setSearchResults] = useState([]);
    const [savedTrials, setSavedTrials] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [showGanttChart, setShowGanttChart] = useState(false);
    // New state variables
    const [companyName, setCompanyName] = useState('');
    const [companyInsights, setCompanyInsights] = useState(null);


    useEffect(() => {
        fetchSavedTrials();
    }, []);

    const fetchSavedTrials = async () => {
        try {
            const response = await axios.get('/api/admin/clinical-trial/saved');
            setSavedTrials(response.data);
        } catch (error) {
            console.error('Error fetching saved trials:', error);
            setError('Failed to fetch saved trials. Please try again.');
        }
    };

    const handleSearch = async (e, pageToken = null) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get('/api/admin/clinical-trial/search', {
                params: { ...searchParams, pageToken }
            });
            setSearchResults(response.data.studies);
            setTotalCount(response.data.totalCount);
            setNextPageToken(response.data.nextPageToken);
            setCurrentPage(pageToken ? currentPage + 1 : 1);
        } catch (error) {
            console.error('Error searching clinical trials:', error);
            setError(error.response?.data?.message || 'An error occurred while searching. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleNextPage = (e) => {
        if (nextPageToken) {
            handleSearch(e, nextPageToken);
        }
    };

    const handlePrevPage = (e) => {
        if (currentPage > 1) {
            handleSearch(e);
            setCurrentPage(currentPage - 1);
        }
    };

    const handleSave = async (trial) => {
        try {
            await axios.post('/api/admin/clinical-trial/save', trial);
            fetchSavedTrials();
            setError(null);
        } catch (error) {
            console.error('Error saving trial:', error);
            setError('Failed to save the trial. Please try again.');
        }
    };

    const handleDelete = async (nctId) => {
        try {
            await axios.delete(`/api/admin/clinical-trial/${nctId}`);
            fetchSavedTrials();
            setError(null);
        } catch (error) {
            console.error('Error deleting trial:', error);
            setError('Failed to delete the trial. Please try again.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prevParams => ({ ...prevParams, [name]: value }));
    };

    // New handler functions
    const handleAnalyzePipeline = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.post('/api/admin/company/analyze-pipeline', { companyName });
            setCompanyInsights(response.data.pipelineInsights);
        } catch (error) {
            console.error('Error analyzing pipeline:', error);
            setError('Failed to analyze company pipeline. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <h1 className="text-2xl font-semibold mb-6">Pipeline Monitor</h1>

            <h2 className="text-xl font-semibold my-6">Company Pipeline Analysis</h2>
            <div className="mb-6">
                <input
                    type="text"
                    placeholder="Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
                />
                <button
                    onClick={handleAnalyzePipeline}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200 flex items-center justify-center disabled:bg-blue-300 m-2"
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <>
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Analyzing...
                        </>
                    ) : (
                        'Analyze Pipeline'
                    )}
                </button>
            </div>

            {companyInsights && (
                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-2">Pipeline Insights</h3>
                    <div className="bg-gray-100 p-4 rounded-md prose max-w-none">
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {companyInsights}
                        </ReactMarkdown>
                    </div>
                </div>
            )}


            <hr className="my-6 border-t border-gray-300" />

            <form onSubmit={(e) => handleSearch(e)} className="mb-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <input
                        type="text"
                        name="manufacturerName"
                        placeholder="Manufacturer Name"
                        value={searchParams.manufacturerName}
                        onChange={handleInputChange}
                        className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <input
                        type="text"
                        name="diseaseDescription"
                        placeholder="Disease Description"
                        value={searchParams.diseaseDescription}
                        onChange={handleInputChange}
                        className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <input
                        type="text"
                        name="drugName"
                        placeholder="Drug Name"
                        value={searchParams.drugName}
                        onChange={handleInputChange}
                        className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <button type="submit" className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
                    <Search className="inline-block mr-2" size={20} />
                    Search
                </button>
            </form>

            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <strong className="font-bold">Error: </strong>
                    <span className="block sm:inline">{error}</span>
                    <AlertTriangle className="inline-block ml-2" size={20} />
                </div>
            )}

            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
            ) : (
                <>
                    <h2 className="text-xl font-semibold mb-4">Search Results</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th className="py-3 px-4 text-left">NCT ID</th>
                                    <th className="py-3 px-4 text-left">Title</th>
                                    <th className="py-3 px-4 text-left">Condition</th>
                                    <th className="py-3 px-4 text-left">Intervention</th>
                                    <th className="py-3 px-4 text-left">Sponsor</th>
                                    <th className="py-3 px-4 text-left">Phase</th>
                                    <th className="py-3 px-4 text-left">Start Date</th>
                                    <th className="py-3 px-4 text-left">Completion Date</th>
                                    <th className="py-3 px-4 text-left">Status</th>
                                    <th className="py-3 px-4 text-left">Study Type</th>
                                    <th className="py-3 px-4 text-left">Enrollment</th>
                                    <th className="py-3 px-4 text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults.map((trial) => (
                                    <tr key={trial.nctId} className="border-b border-gray-200 hover:bg-gray-50">
                                        <td className="py-3 px-4">{trial.nctId}</td>
                                        <td className="py-3 px-4">{trial.briefTitle}</td>
                                        <td className="py-3 px-4">{trial.condition}</td>
                                        <td className="py-3 px-4">{trial.interventionName}</td>
                                        <td className="py-3 px-4">{trial.sponsorName}</td>
                                        <td className="py-3 px-4">{trial.phase}</td>
                                        <td className="py-3 px-4">{trial.startDate}</td>
                                        <td className="py-3 px-4">{trial.completionDate}</td>
                                        <td className="py-3 px-4">{trial.overallStatus}</td>
                                        <td className="py-3 px-4">{trial.studyType}</td>
                                        <td className="py-3 px-4">{trial.enrollmentCount}</td>
                                        <td className="py-3 px-4">
                                            <button onClick={() => handleSave(trial)} className="text-green-500 hover:text-green-700 mr-2">
                                                <Save size={18} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-4 flex justify-between items-center">
                        <span>Showing {searchResults.length} of {totalCount} results</span>
                        <div>
                            <button
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                                className="mr-2 px-3 py-1 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
                            >
                                <ChevronLeft size={20} />
                            </button>
                            <button
                                onClick={handleNextPage}
                                disabled={!nextPageToken}
                                className="px-3 py-1 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
                            >
                                <ChevronRight size={20} />
                            </button>
                        </div>
                    </div>

                    <button
                        onClick={() => setShowGanttChart(!showGanttChart)}
                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200"
                    >
                        {showGanttChart ? 'Hide Gantt Chart' : 'Show Gantt Chart'}
                    </button>

                    {showGanttChart && searchResults.length > 0 && (
                        <div className="mt-6">
                            <h3 className="text-lg font-semibold mb-2">Trial Timelines</h3>
                            <div className="overflow-x-auto border border-gray-200 rounded-lg">
                                <GanttChart data={searchResults} />
                            </div>
                        </div>
                    )}
                </>
            )}
            <h2 className="text-xl font-semibold my-6">Saved Trials</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="py-3 px-4 text-left">NCT ID</th>
                            <th className="py-3 px-4 text-left">Title</th>
                            <th className="py-3 px-4 text-left">Condition</th>
                            <th className="py-3 px-4 text-left">Intervention</th>
                            <th className="py-3 px-4 text-left">Sponsor</th>
                            <th className="py-3 px-4 text-left">Phase</th>
                            <th className="py-3 px-4 text-left">Start Date</th>
                            <th className="py-3 px-4 text-left">Completion Date</th>
                            <th className="py-3 px-4 text-left">Status</th>
                            <th className="py-3 px-4 text-left">Study Type</th>
                            <th className="py-3 px-4 text-left">Enrollment</th>
                            <th className="py-3 px-4 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {savedTrials.map((trial) => (
                            <tr key={trial.nctId} className="border-b border-gray-200 hover:bg-gray-50">
                                <td className="py-3 px-4">{trial.nctId}</td>
                                <td className="py-3 px-4">{trial.briefTitle}</td>
                                <td className="py-3 px-4">{trial.condition}</td>
                                <td className="py-3 px-4">{trial.interventionName}</td>
                                <td className="py-3 px-4">{trial.sponsorName}</td>
                                <td className="py-3 px-4">{trial.phase}</td>
                                <td className="py-3 px-4">{trial.startDate}</td>
                                <td className="py-3 px-4">{trial.completionDate}</td>
                                <td className="py-3 px-4">{trial.overallStatus}</td>
                                <td className="py-3 px-4">{trial.studyType}</td>
                                <td className="py-3 px-4">{trial.enrollmentCount}</td>
                                <td className="py-3 px-4">
                                    <button onClick={() => handleDelete(trial.nctId)} className="text-red-500 hover:text-red-700">
                                        <Trash2 size={18} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


        </div>
    );
};

export default PipelineMonitor;