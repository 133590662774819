import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ArrowRight, BarChart2, Globe, Database, Menu } from 'lucide-react';
import SearchPage from './components/SearchPage';
import AdminLayout from './components/AdminLayout';
import AdminDashboard from './components/AdminDashboard';
import AdminDrugReviews from './components/AdminDrugReviews';
import AdminPCPAReviews from './components/AdminpCPAReviews';
import PCPAReviewPage from './components/PCPAReviewPage';
import Registration from './components/Registration';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/Login';
import ReportBuilder from './components/ReportBuilder/ReportBuilder';
import ODBDrugsAdmin from './components/AdminODBDrugs';
import AdminReportPrompts from './components/AdminReportPrompts';
import AdminPipelineMonitor from './components/AdminPipelineMonitor';
import AdminCompany from './components/AdminCompany';
import AdminPublications from './components/AdminPublications';
import LLMSearch from './components/LLMSearch';

const Feature = ({ icon, title, description }) => (
  <div className="flex flex-col items-center p-4 sm:p-6 bg-white rounded-lg shadow-md">
    {icon}
    <h3 className="mt-4 text-lg sm:text-xl font-semibold">{title}</h3>
    <p className="mt-2 text-sm sm:text-base text-gray-600 text-center">{description}</p>
  </div>
);

const LandingPage = () => (
  <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
    <main>
      <section className="container mx-auto px-4 py-12 sm:py-20 text-center">
        <h2 className="text-3xl sm:text-5xl font-bold mb-4 sm:mb-6">Global HTA Strategy Analytics</h2>
        <p className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-8">Gain insights from Health Technology Assessment agencies worldwide</p>
        <Link to="/search" className="bg-blue-600 text-white px-6 sm:px-8 py-2 sm:py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-blue-700 transition duration-300 inline-block">
          Get Started <ArrowRight className="inline ml-2" />
        </Link>
      </section>

      <section id="features" className="container mx-auto px-4 py-12 sm:py-20">
        <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8 sm:mb-12">Our Features</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          <Feature
            icon={<Globe className="w-10 h-10 sm:w-12 sm:h-12 text-blue-600" />}
            title="Global Coverage"
            description="Access data from HTAs around the world"
          />
          <Feature
            icon={<BarChart2 className="w-10 h-10 sm:w-12 sm:h-12 text-blue-600" />}
            title="Advanced Analytics"
            description="Powerful tools for strategy analysis"
          />
          <Feature
            icon={<Database className="w-10 h-10 sm:w-12 sm:h-12 text-blue-600" />}
            title="Comprehensive Data"
            description="In-depth information on HTA strategies"
          />
        </div>
      </section>

      <section id="about" className="bg-blue-600 text-white py-12 sm:py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">About AxsInsights</h2>
          <p className="text-base sm:text-xl">
            We provide cutting-edge data and analytics on Health Technology Assessment strategies,
            helping businesses make informed decisions in the global healthcare market.
          </p>
        </div>
      </section>

      <section id="contact" className="container mx-auto px-4 py-12 sm:py-20 text-center">
        <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">Get in Touch</h2>
        <p className="text-base sm:text-xl mb-6 sm:mb-8">Interested in our services? Contact us for more information.</p>
        <button className="bg-blue-600 text-white px-6 sm:px-8 py-2 sm:py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-blue-700 transition duration-300">
          Contact Us
        </button>
      </section>
    </main>
  </div>
);

const Navigation = () => {
  const { isAuthenticated, isAdmin, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="relative w-full">
      <div className="flex items-center justify-between">
        <button
          onClick={toggleMenu}
          className="sm:hidden text-gray-600 hover:text-blue-600 focus:outline-none"
          aria-label="Toggle menu"
        >
          <Menu size={24} />
        </button>
        <div className={`${
          isMenuOpen ? 'block' : 'hidden'
        } sm:flex absolute sm:relative top-full left-0 w-full sm:w-auto bg-white sm:bg-transparent shadow-md sm:shadow-none z-20`}>
          <ul className="flex flex-col sm:flex-row sm:space-x-6 p-4 sm:p-0">
            <li><Link to="/" className="block py-2 text-gray-600 hover:text-blue-600" onClick={closeMenu}>Home</Link></li>
            {!isAuthenticated && (
              <>
                <li><Link to="/login" className="block py-2 text-gray-600 hover:text-blue-600" onClick={closeMenu}>Login</Link></li>
                <li><Link to="/register" className="block py-2 text-gray-600 hover:text-blue-600" onClick={closeMenu}>Register</Link></li>
              </>
            )}
            {isAdmin && (
              <>
                <li><Link to="/report-builder" className="block py-2 text-gray-600 hover:text-blue-600" onClick={closeMenu}>Report Builder</Link></li>
                <li><Link to="/llm-query" className="block py-2 text-gray-600 hover:text-blue-600" onClick={closeMenu}>LLM Query</Link></li>
                <li><Link to="/admin" className="block py-2 text-gray-600 hover:text-blue-600" onClick={closeMenu}>Admin</Link></li>
              </>
            )}
            {isAuthenticated && (
              <>
                <li><Link to="/search" className="block py-2 text-gray-600 hover:text-blue-600" onClick={closeMenu}>Search</Link></li>
                <li><button onClick={() => { logout(); closeMenu(); }} className="block w-full text-left py-2 text-gray-600 hover:text-blue-600">Logout</button></li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
          <header className="container mx-auto px-4 py-4 sm:py-6">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="flex items-center mb-4 sm:mb-0">
                <h1 className="text-2xl sm:text-3xl font-bold text-blue-600 mr-4">AxsInsights</h1>
                <p className="text-xs text-gray-500">A BioSpark / 360 Public Affairs Collaboration</p>
              </div>
              <Navigation />
            </div>
          </header>

          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/pcpa-reviews" element={<PCPAReviewPage />} />
            <Route path="/report-builder" element={<ReportBuilder />} />
            <Route path="/llm-query" element={<LLMSearch />} />
            <Route path="/admin" element={<AdminLayout />}>
              <Route index element={<AdminDashboard />} />
              <Route path="drug-reviews" element={<AdminDrugReviews />} />
              <Route path="pcpa-reviews" element={<AdminPCPAReviews />} />
              <Route path="odb-drugs" element={<ODBDrugsAdmin />} />
              <Route path="report-prompts" element={<AdminReportPrompts />} />
              <Route path="pipeline-monitor" element={<AdminPipelineMonitor />} />
              <Route path="companies" element={<AdminCompany />} />
              <Route path="publications" element={<AdminPublications />} />
            </Route>
          </Routes>

          <footer className="bg-gray-100 py-4 sm:py-6">
            <div className="container mx-auto px-4 text-center text-sm sm:text-base text-gray-600">
              &copy; 2024 AxsInsights. All rights reserved.
            </div>
          </footer>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;