import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Search, ChevronLeft, ChevronRight, X, Trash2 } from 'lucide-react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';
import { Toaster, toast } from 'react-hot-toast';

const chapters = [
  { id: 'chapter-1', title: 'Executive Summary' },
  { id: 'chapter-2', title: 'Disease Area Summary' },
  { id: 'chapter-3', title: 'HTA Recommendation(s)' },
  { id: 'chapter-4', title: 'Clinical Evidence' },
  { id: 'chapter-5', title: 'CE & Pricing Insights' },
  { id: 'chapter-6', title: 'Stakeholder Input' },
  { id: 'chapter-7', title: 'Analog Analysis' },
  { id: 'chapter-8', title: 'pCPA Insights' },
];

const EditModal = ({ prompt, onClose, onSave, isNew }) => {
  const [editedPrompt, setEditedPrompt] = useState(prompt || {
    reportChapter: { id: chapters[0].id, title: chapters[0].title },
    promptTemplate: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'reportChapter.id') {
      const chapter = chapters.find(ch => ch.id === value);
      setEditedPrompt(prev => ({ ...prev, reportChapter: chapter }));
    } else {
      setEditedPrompt(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleCodeChange = (value) => {
    setEditedPrompt(prev => ({ ...prev, promptTemplate: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(editedPrompt);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-8 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">{isNew ? 'Add New Prompt Template' : 'Edit Prompt Template'}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium text-gray-700">Report Chapter</label>
            <select
              name="reportChapter.id"
              value={editedPrompt.reportChapter.id}
              onChange={handleChange}
              className="border rounded-md px-3 py-2"
            >
              {chapters.map(chapter => (
                <option key={chapter.id} value={chapter.id}>
                  {chapter.title}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium text-gray-700">Prompt Template</label>
            <CodeMirror
              value={editedPrompt.promptTemplate}
              height="200px"
              extensions={[javascript({ jsx: true })]}
              theme={oneDark}
              onChange={handleCodeChange}
            />
          </div>
          <div className="flex justify-end space-x-2 mt-6">
            <button type="button" onClick={onClose} className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300">
              Cancel
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const AdminReportPrompts = () => {
  const { user } = useAuth();
  const [prompts, setPrompts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewPrompt, setIsNewPrompt] = useState(false);
  const [deletePromptId, setDeletePromptId] = useState(null);

  const fetchPrompts = async (page = 1, search = '') => {
    setIsLoading(true);
    try {
      const response = await axios.get('/api/admin/prompt-templates', {
        params: { page, limit: 5, search }
      });
      setPrompts(response.data.prompts);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error('Error fetching prompts:', error);
      setPrompts([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPrompts(1, '');
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchPrompts(1, searchTerm);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchPrompts(newPage, searchTerm);
  };

  const handleRowClick = (prompt) => {
    setSelectedPrompt(prompt);
    setIsModalOpen(true);
    setIsNewPrompt(false);
  };

  const handleCloseModal = () => {
    setSelectedPrompt(null);
    setIsModalOpen(false);
  };

  const handleSavePrompt = async (editedPrompt) => {
    try {
      const promptData = { ...editedPrompt, user: user.id };
      // console.log('Saving prompt data:', promptData); // Log the data being sent
      
      let response;
      if (isNewPrompt) {
        response = await axios.post('/api/admin/prompt-templates', promptData);
      } else {
        response = await axios.put(`/api/admin/prompt-templates/${editedPrompt._id}`, promptData);
      }
      
      // console.log('Server response:', response.data); // Log the server response
      
      if (isNewPrompt) {
        setPrompts([...prompts, response.data]);
      } else {
        setPrompts(prompts.map(prompt => prompt._id === editedPrompt._id ? response.data : prompt));
      }
      
      setSelectedPrompt(null);
      setIsModalOpen(false);
      toast.success('Prompt template saved successfully!');
      fetchPrompts(currentPage, searchTerm); // Refresh the list after saving
    } catch (error) {
      console.error('Error saving prompt template:', error);
      console.error('Error response:', error.response); // Log the full error response
      toast.error(`Error saving prompt template: ${error.message}`);
    }
  };

  const handleDeleteClick = (e, promptId) => {
    e.stopPropagation(); // Prevent row click event
    setDeletePromptId(promptId);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`/api/admin/prompt-templates/${deletePromptId}`);
      setPrompts(prompts.filter(prompt => prompt._id !== deletePromptId));
      setDeletePromptId(null);
      toast.success('Prompt template deleted successfully!');
    } catch (error) {
      console.error('Error deleting prompt template:', error);
      toast.error(`Error deleting prompt template: ${error.message}`);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <Toaster position="top-right" />
      <h1 className="text-2xl font-semibold mb-6">Report Prompts</h1>
  
      <div className="flex justify-between items-center mb-6">
        <form onSubmit={handleSearch} className="flex-grow mr-4">
          <div className="flex">
            <input
              type="text"
              placeholder="Search prompts..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-blue-500"
            />
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-200">
              <Search size={20} />
            </button>
          </div>
        </form>
        <button
          onClick={() => { setIsModalOpen(true); setIsNewPrompt(true); setSelectedPrompt(null); }}
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-200"
        >
          Add New Prompt
        </button>
      </div>
  
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      ) : prompts.length === 0 ? (
        <div className="text-center py-4">No prompts found.</div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-3 px-4 text-left">Report Chapter</th>
                  <th className="py-3 px-4 text-left">Prompt Template</th>
                  <th className="py-3 px-4 text-left">User</th>
                  <th className="py-3 px-4 text-left">Created</th>
                  <th className="py-3 px-4 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {prompts.map((prompt) => (
                  <tr 
                    key={prompt._id} 
                    className="border-b border-gray-200 hover:bg-gray-50 cursor-pointer" 
                    onClick={() => handleRowClick(prompt)}
                  >
                    <td className="py-3 px-4">{prompt.reportChapter.title}</td>
                    <td className="py-3 px-4">{prompt.promptTemplate.substring(0, 50)}...</td>
                    <td className="py-3 px-4">{prompt.user.name}</td>
                    <td className="py-3 px-4">{new Date(prompt.created).toLocaleString()}</td>
                    <td className="py-3 px-4">
                      <button
                        onClick={(e) => handleDeleteClick(e, prompt._id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <Trash2 size={20} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
  
          <div className="mt-6 flex justify-between items-center">
            <span>
              Showing {prompts.length} of {totalPages * 5} prompts | Page {currentPage} of {totalPages}
            </span>
            <div className="flex space-x-2">
              <button
                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition duration-200"
              >
                <ChevronLeft size={20} />
              </button>
              <button
                onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed transition duration-200"
              >
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </>
      )}
  
      {isModalOpen && (
        <EditModal
          prompt={selectedPrompt}
          onClose={handleCloseModal}
          onSave={handleSavePrompt}
          isNew={isNewPrompt}
        />
      )}
  
      {deletePromptId && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-6 max-w-sm">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p className="mb-4">Are you sure you want to delete this prompt template?</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setDeletePromptId(null)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminReportPrompts;