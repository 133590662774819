import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { Search, FileText, CheckSquare, XSquare, Download, ChevronLeft, ChevronRight, FileCheck, FileX, RefreshCw, Loader, ChevronDown, ChevronUp } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const PageSelectionModal = ({ isOpen, onClose, reports, chapters, onConfirm }) => {
  const [pageSelections, setPageSelections] = useState({});
  const [activeReport, setActiveReport] = useState(null);
  const [activeChapter, setActiveChapter] = useState(null);

  useEffect(() => {
    if (isOpen && reports.length > 0 && chapters.length > 0) {
      const initialSelections = reports.reduce((acc, report) => {
        acc[report._id] = chapters.reduce((chapterAcc, chapter) => {
          chapterAcc[chapter.id] = {
            reportType: 'clinical',
            startPage: 0,
            endPage: 0,
          };
          return chapterAcc;
        }, {});
        return acc;
      }, {});
      setPageSelections(initialSelections);
      setActiveReport(reports[0]._id);
      setActiveChapter(chapters[0].id);
    }
  }, [isOpen, reports, chapters]);

  const handleSelectionChange = (reportId, chapterId, field, value) => {
    setPageSelections(prev => ({
      ...prev,
      [reportId]: {
        ...prev[reportId],
        [chapterId]: {
          ...prev[reportId][chapterId],
          [field]: value,
        },
      },
    }));
  };

  const getPageContent = (report, chapterId) => {
    const selection = pageSelections[report._id]?.[chapterId];
    if (!selection) return 'No selection made';

    const pages = selection.reportType === 'clinical'
      ? report.clinical_review_report_pages
      : report.recommendation_and_reasons_pages;

    if (!pages) return 'No pages available for the selected report type';

    return pages
      .slice(selection.startPage, selection.endPage + 1)
      .map((page, index) => (
        `Page ${selection.startPage + index + 1}:\n${page.content}`
      ))
      .join('\n\n');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Select Pages for Each Chapter</h2>

        <div className="flex mb-4">
          <div className="w-1/4 pr-4">
            <h3 className="font-semibold mb-2">Reports</h3>
            {reports.map(report => (
              <button
                key={report._id}
                onClick={() => setActiveReport(report._id)}
                className={`block w-full text-left p-2 rounded ${activeReport === report._id ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
              >
                {report.brand_name}
              </button>
            ))}
          </div>

          <div className="w-3/4">
            <div className="flex mb-4">
              {chapters.map(chapter => (
                <button
                  key={chapter.id}
                  onClick={() => setActiveChapter(chapter.id)}
                  className={`mr-2 px-3 py-1 rounded ${activeChapter === chapter.id ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                  {chapter.title}
                </button>
              ))}
            </div>

            {activeReport && activeChapter && (
              <div>
                <h4 className="font-semibold mb-2">
                  {reports.find(r => r._id === activeReport).brand_name} - {chapters.find(c => c.id === activeChapter).title}
                </h4>
                <div className="flex items-center space-x-4 mb-2">
                  <select
                    value={pageSelections[activeReport]?.[activeChapter]?.reportType || 'clinical'}
                    onChange={(e) => handleSelectionChange(activeReport, activeChapter, 'reportType', e.target.value)}
                    className="border rounded px-2 py-1"
                  >
                    <option value="clinical">Clinical Review Report</option>
                    <option value="recommendation">Recommendation and Reasons Report</option>
                  </select>
                  <div className="flex items-center">
                    <label className="flex items-center">
                      <span className="mr-2">Start Page:</span>
                      <input
                        type="number"
                        min="0"
                        value={pageSelections[activeReport]?.[activeChapter]?.startPage || 0}
                        onChange={(e) => handleSelectionChange(activeReport, activeChapter, 'startPage', parseInt(e.target.value))}
                        className="border rounded px-2 py-1 w-16"
                      />
                    </label>
                    <div className="flex flex-col ml-1">
                      <button onClick={() => handleSelectionChange(activeReport, activeChapter, 'startPage', (pageSelections[activeReport]?.[activeChapter]?.startPage || 0) + 1)} className="text-gray-500 hover:text-gray-700">
                        <ChevronUp size={16} />
                      </button>
                      <button onClick={() => handleSelectionChange(activeReport, activeChapter, 'startPage', Math.max((pageSelections[activeReport]?.[activeChapter]?.startPage || 0) - 1, 0))} className="text-gray-500 hover:text-gray-700">
                        <ChevronDown size={16} />
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label className="flex items-center">
                      <span className="mr-2">End Page:</span>
                      <input
                        type="number"
                        min={pageSelections[activeReport]?.[activeChapter]?.startPage || 0}
                        value={pageSelections[activeReport]?.[activeChapter]?.endPage || 0}
                        onChange={(e) => handleSelectionChange(activeReport, activeChapter, 'endPage', parseInt(e.target.value))}
                        className="border rounded px-2 py-1 w-16"
                      />
                    </label>
                    <div className="flex flex-col ml-1">
                      <button onClick={() => handleSelectionChange(activeReport, activeChapter, 'endPage', (pageSelections[activeReport]?.[activeChapter]?.endPage || 0) + 1)} className="text-gray-500 hover:text-gray-700">
                        <ChevronUp size={16} />
                      </button>
                      <button onClick={() => handleSelectionChange(activeReport, activeChapter, 'endPage', Math.max((pageSelections[activeReport]?.[activeChapter]?.endPage || 0) - 1, pageSelections[activeReport]?.[activeChapter]?.startPage || 0))} className="text-gray-500 hover:text-gray-700">
                        <ChevronDown size={16} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="border p-4 h-64 overflow-y-auto bg-gray-50 rounded">
                  <pre className="text-sm whitespace-pre-wrap">
                    {getPageContent(reports.find(r => r._id === activeReport), activeChapter)}
                  </pre>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <button onClick={onClose} className="mr-2 px-4 py-2 bg-gray-200 rounded">Cancel</button>
          <button onClick={() => onConfirm(pageSelections)} className="px-4 py-2 bg-blue-500 text-white rounded">Confirm</button>
        </div>
      </div>
    </div>
  );
};
const ReportBuilder = () => {
  const [query, setQuery] = useState('');
  const [htaReports, setHtaReports] = useState([]);
  const [selectedReports, setSelectedReports] = useState([]);
  const [generatedAssessment, setGeneratedAssessment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [conversionTasks, setConversionTasks] = useState({});
  const [isPageChanging, setIsPageChanging] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [diseaseArea, setDiseaseArea] = useState('');
  const [expandedChapters, setExpandedChapters] = useState({});
  const [isPageSelectionModalOpen, setIsPageSelectionModalOpen] = useState(false);
  const [chapters, setChapters] = useState([
    { id: 'chapter-1', title: 'Executive Summary', selected: true },
    { id: 'chapter-2', title: 'Disease Area Summary', selected: true },
    { id: 'chapter-3', title: 'HTA Recommendation(s)', selected: true },
    { id: 'chapter-4', title: 'Clinical Evidence', selected: true },
    { id: 'chapter-5', title: 'CE & Pricing Insights', selected: true },
    { id: 'chapter-6', title: 'Stakeholder Input', selected: true },
    { id: 'chapter-7', title: 'Analog Analysis', selected: true },
    { id: 'chapter-8', title: 'pCPA Insights', selected: true },
  ]);

  const searchReports = useCallback(async (page = 1) => {
    setIsLoading(true);
    setIsPageChanging(true);
    try {
      const response = await axios.get('/api/admin/drug-reviews', {
        params: { page, limit: 10, search: query }
      });
      setHtaReports(response.data.reviews);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
      setTotalResults(response.data.totalReviews);
      setHasSearched(true);
    } catch (error) {
      console.error('Error searching reports:', error);
    } finally {
      setIsLoading(false);
      setIsPageChanging(false);
    }
  }, [query]);

  const handleDiseaseAreaChange = (e) => {
    setDiseaseArea(e.target.value);
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) {
      searchReports(1);
    }
  };

  const toggleReportSelection = (report) => {
    setSelectedReports(prev =>
      prev.includes(report) ? prev.filter(r => r !== report) : [...prev, report]
    );
  };

  const handleGenerateAssessment = () => {
    setIsPageSelectionModalOpen(true);
  };

  const generateLandscapeAssessment = async (selections) => {
    setIsLoading(true);
    try {
      const selectedChapters = chapters.filter(chapter => chapter.selected);
      const response = await axios.post('/api/generate-landscape-assessment', {
        reports: selectedReports,
        chapters: selectedChapters.map(chapter => chapter.id),
        diseaseArea: diseaseArea,
        pageSelections: selections
      });

      setGeneratedAssessment(response.data.assessment);

      if (response.data.assessment && response.data.assessment.chapters) {
        setExpandedChapters(response.data.assessment.chapters.reduce((acc, chapter) => {
          acc[chapter.id] = false;
          return acc;
        }, {}));
      }
    } catch (error) {
      console.error('Error generating landscape assessment:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages && !isPageChanging) {
      searchReports(newPage);
    }
  };

  const toggleChapter = (chapterId) => {
    setChapters(prev => prev.map(chapter =>
      chapter.id === chapterId ? { ...chapter, selected: !chapter.selected } : chapter
    ));
  };

  const toggleChapterExpansion = (chapterId) => {
    setExpandedChapters(prev => ({
      ...prev,
      [chapterId]: !prev[chapterId]
    }));
  };

  const startPdfConversion = async (report, type) => {
    try {
      const url = type === 'clinical'
        ? `https://www.cadth.ca/${report.clinical_review_report_url}`
        : `https://www.cadth.ca/${report.recommendation_and_reasons_report_url}`;

      if (!url) {
        console.error(`No URL available for ${type} conversion`);
        return;
      }

      const response = await axios.post('/api/report-builder/convert-pdf', {
        reportId: report._id,
        pdfUrl: url,
        conversionType: type
      });

      setConversionTasks(prev => ({
        ...prev,
        [report._id]: {
          ...prev[report._id],
          [type]: { taskId: response.data.taskId, status: 'processing' }
        }
      }));
    } catch (error) {
      console.error(`Error starting ${type} PDF conversion:`, error);
    }
  };

  const checkConversionStatus = useCallback(async (reportId, taskId, type) => {
    try {
      const response = await axios.get(`/api/report-builder/conversion-status/${taskId}`);
      if (response.data.status === 'completed') {
        await axios.put(`/api/report-builder/update-report/${reportId}`, { taskId, conversionType: type });

        setConversionTasks(prev => ({
          ...prev,
          [reportId]: {
            ...prev[reportId],
            [type]: { ...prev[reportId][type], status: 'completed' }
          }
        }));

        searchReports(currentPage);
      } else {
        setTimeout(() => checkConversionStatus(reportId, taskId, type), 5000);
      }
    } catch (error) {
      console.error(`Error checking ${type} conversion status:`, error);
    }
  }, [currentPage, searchReports]);

  useEffect(() => {
    Object.entries(conversionTasks).forEach(([reportId, tasks]) => {
      if (tasks.clinical?.status === 'processing') {
        checkConversionStatus(reportId, tasks.clinical.taskId, 'clinical');
      }
      if (tasks.recommendation?.status === 'processing') {
        checkConversionStatus(reportId, tasks.recommendation.taskId, 'recommendation');
      }
    });
  }, [conversionTasks, checkConversionStatus]);

  const renderMarkdown = (content) => (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        h1: ({ node, ...props }) => <h1 className="text-3xl font-bold my-6" {...props} />,
        h2: ({ node, ...props }) => <h2 className="text-2xl font-semibold my-5" {...props} />,
        h3: ({ node, ...props }) => <h3 className="text-xl font-medium my-4" {...props} />,
        h4: ({ node, ...props }) => <h4 className="text-lg font-medium my-3" {...props} />,
        p: ({ node, ...props }) => <p className="my-3 leading-relaxed" {...props} />,
        ul: ({ node, ...props }) => <ul className="list-disc list-inside my-3 pl-4" {...props} />,
        ol: ({ node, ...props }) => <ol className="list-decimal list-inside my-3 pl-4" {...props} />,
        li: ({ node, ...props }) => <li className="my-1" {...props} />,
        a: ({ node, ...props }) => <a className="text-blue-600 hover:underline" {...props} />,
        blockquote: ({ node, ...props }) => (
          <blockquote className="border-l-4 border-gray-300 pl-4 my-4 italic bg-gray-50 py-2" {...props} />
        ),
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={atomDark}
              language={match[1]}
              PreTag="div"
              className="rounded-md my-4"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className="bg-gray-100 rounded px-1 py-0.5 text-sm" {...props}>
              {children}
            </code>
          );
        },
        table: ({ node, ...props }) => (
          <div className="overflow-x-auto my-4">
            <table className="min-w-full divide-y divide-gray-200" {...props} />
          </div>
        ),
        thead: ({ node, ...props }) => <thead className="bg-gray-50" {...props} />,
        th: ({ node, ...props }) => (
          <th
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            {...props}
          />
        ),
        td: ({ node, ...props }) => <td className="px-6 py-4 whitespace-nowrap" {...props} />,
        hr: ({ node, ...props }) => <hr className="my-6 border-t border-gray-300" {...props} />,
        img: ({ node, ...props }) => (
          <img className="max-w-full h-auto rounded-lg my-4" {...props} alt={props.alt || ''} />
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <main className="container mx-auto px-4 py-20">
        <h1 className="text-5xl font-bold mb-6 text-center">Market Access Report Builder</h1>
        <p className="text-xl text-gray-600 mb-8 text-center">Search and analyze CDA drug reviews</p>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">Search CDA Reports</h2>
          <form onSubmit={handleSearch} className="flex items-center mb-4">
            <input
              value={query}
              onChange={handleInputChange}
              placeholder="Search by brand name, generic name, therapeutic area, etc."
              className="flex-grow px-4 py-2 rounded-l-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-2 rounded-r-md text-lg font-semibold hover:bg-blue-700 transition duration-300 flex items-center justify-center"
              disabled={isLoading}
            >
              {isLoading ? 'Searching...' : (
                <>
                  Search <Search className="ml-2 h-5 w-5" />
                </>
              )}
            </button>
          </form>
        </div>

        {hasSearched && (
          <>
            {htaReports.length > 0 ? (
              <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                <h2 className="text-2xl font-semibold mb-4">CADTH Reports</h2>
                <p className="text-gray-600 mb-4">Showing {htaReports.length} of {totalResults} results</p>
                <div className="space-y-4">
                  {htaReports.map(report => (
                    <div key={report._id} className="flex items-center justify-between p-4 border rounded-md">
                      <div>
                        <h3 className="font-medium">{report.brand_name} ({report.generic_name})</h3>
                        <p className="text-sm text-gray-600">Therapeutic Area: {report.therapeutic_area}</p>
                        <p className="text-sm text-gray-600">Recommendation: {report.recommendation_type}</p>
                      </div>
                      <div className="flex items-center space-x-4">
                        <div className="flex flex-col items-center">
                          {report.clinical_review_report_text ? (
                            <FileCheck className="text-green-600 h-6 w-6" title="Clinical review report available" />
                          ) : report.clinical_review_report_url ? (
                            <button
                              onClick={() => startPdfConversion(report, 'clinical')}
                              className="p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition duration-300"
                              disabled={conversionTasks[report._id]?.clinical?.status === 'processing'}
                            >
                              {conversionTasks[report._id]?.clinical?.status === 'processing' ? (
                                <RefreshCw className="h-6 w-6 animate-spin" />
                              ) : (
                                <FileX className="h-6 w-6" title="Convert clinical review report" />
                              )}
                            </button>
                          ) : (
                            <FileX className="text-gray-400 h-6 w-6" title="Clinical review report not available" />
                          )}
                          <span className="text-xs mt-1">Clinical Review</span>
                        </div>
                        <div className="flex flex-col items-center">
                          {report.recommendation_and_reasons_text ? (
                            <FileCheck className="text-green-600 h-6 w-6" title="Recommendation and reasons report available" />
                          ) : report.recommendation_and_reasons_report_url ? (
                            <button
                              onClick={() => startPdfConversion(report, 'recommendation')}
                              className="p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition duration-300"
                              disabled={conversionTasks[report._id]?.recommendation?.status === 'processing'}
                            >
                              {conversionTasks[report._id]?.recommendation?.status === 'processing' ? (
                                <RefreshCw className="h-6 w-6 animate-spin" />
                              ) : (
                                <FileX className="h-6 w-6" title="Convert recommendation and reasons report" />
                              )}
                            </button>
                          ) : (
                            <FileX className="text-gray-400 h-6 w-6" title="Recommendation and reasons report not available" />
                          )}
                          <span className="text-xs mt-1">Recommendation</span>
                        </div>
                        <button
                          onClick={() => toggleReportSelection(report)}
                          className={`p-2 rounded-full transition duration-300 ${selectedReports.includes(report)
                              ? 'bg-green-100 text-green-600 hover:bg-green-200'
                              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                            }`}
                        >
                          {selectedReports.includes(report) ? (
                            <CheckSquare className="h-6 w-6" />
                          ) : (
                            <XSquare className="h-6 w-6" />
                          )}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-between items-center mt-6">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1 || isPageChanging}
                    className="flex items-center px-4 py-2 bg-gray-200 text-gray-700 rounded-md disabled:opacity-50 transition duration-300"
                  >
                    {isPageChanging && currentPage > 1 ? (
                      <Loader className="animate-spin mr-2 h-4 w-4" />
                    ) : (
                      <ChevronLeft className="mr-2 h-4 w-4" />
                    )}
                    Previous
                  </button>
                  <span className="text-gray-600">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages || isPageChanging}
                    className="flex items-center px-4 py-2 bg-gray-200 text-gray-700 rounded-md disabled:opacity-50 transition duration-300"
                  >
                    Next
                    {isPageChanging && currentPage < totalPages ? (
                      <Loader className="animate-spin ml-2 h-4 w-4" />
                    ) : (
                      <ChevronRight className="ml-2 h-4 w-4" />
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div className="text-center py-8">
                <p className="text-xl text-gray-600">No reports found. Try a different search term.</p>
              </div>
            )}

            {htaReports.length > 0 && (
              <div className="bg-white rounded-lg shadow-md p-6 mt-8">
                <h2 className="text-2xl font-semibold mb-4">Report Generation</h2>
                <div className="mb-4">
                  <label htmlFor="diseaseArea" className="block text-sm font-medium text-gray-700 mb-1">
                    Disease Area
                  </label>
                  <input
                    type="text"
                    id="diseaseArea"
                    value={diseaseArea}
                    onChange={handleDiseaseAreaChange}
                    placeholder="Enter disease area"
                    className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <h3 className="text-xl font-semibold mb-2">Report Chapters</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {chapters.map((chapter) => (
                    <div
                      key={chapter.id}
                      className={`flex items-center justify-between p-3 rounded-md border ${chapter.selected
                          ? 'bg-blue-100 border-blue-300 text-blue-800'
                          : 'bg-gray-100 border-gray-300 text-gray-800'
                        } hover:bg-blue-50 transition duration-300`}
                    >
                      <span className="text-sm font-medium">{chapter.title}</span>
                      <button
                        onClick={() => toggleChapter(chapter.id)}
                        className="ml-2 p-1 rounded-full hover:bg-gray-200 transition duration-300"
                      >
                        {chapter.selected ? (
                          <CheckSquare className="h-5 w-5 text-blue-600" />
                        ) : (
                          <XSquare className="h-5 w-5 text-gray-400" />
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {selectedReports.length > 0 && (
              <div className="text-center mt-8">
                <button
                  onClick={handleGenerateAssessment}
                  className="bg-blue-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300 inline-flex items-center"
                  disabled={isLoading || !diseaseArea.trim()}
                >
                  {isLoading ? (
                    <>
                      <Loader className="animate-spin mr-2 h-5 w-5" />
                      Generating
                    </>
                  ) : (
                    <>
                      Generate Landscape Assessment <FileText className="ml-2 h-5 w-5" />
                    </>
                  )}
                </button>
                {!diseaseArea.trim() && (
                  <p className="text-red-500 mt-2">Please enter a disease area before generating the report.</p>
                )}
              </div>
            )}

            {generatedAssessment && (
              <div className="bg-white rounded-lg shadow-md p-6 mt-8">
                <h2 className="text-2xl font-semibold mb-4">Generated Landscape Assessment</h2>

                {/* Render Executive Summary */}
                <div className="mb-6">
                  <h3 className="text-xl font-semibold mb-2">Executive Summary</h3>
                  <div className="prose max-w-none">
                    {renderMarkdown(generatedAssessment.executiveSummary)}
                  </div>
                </div>

                {/* Render Chapters */}
                {generatedAssessment.chapters.map((chapter) => (
                  <div key={chapter.id} className="mb-4 border rounded-lg overflow-hidden">
                    <div
                      className="bg-gray-100 p-4 flex justify-between items-center cursor-pointer"
                      onClick={() => toggleChapterExpansion(chapter.id)}
                    >
                      <h3 className="text-lg font-medium">{chapter.title}</h3>
                      {expandedChapters[chapter.id] ? <ChevronUp /> : <ChevronDown />}
                    </div>
                    {expandedChapters[chapter.id] && (
                      <div className="p-4 prose max-w-none">
                        {renderMarkdown(chapter.content)}
                      </div>
                    )}
                  </div>
                ))}
                <button className="bg-green-600 text-white px-6 py-2 rounded-full text-lg font-semibold hover:bg-green-700 transition duration-300 inline-flex items-center mt-4">
                  Download Report <Download className="ml-2 h-5 w-5" />
                </button>
              </div>
            )}
          </>
        )}

        <PageSelectionModal
          isOpen={isPageSelectionModalOpen}
          onClose={() => setIsPageSelectionModalOpen(false)}
          reports={selectedReports}
          chapters={chapters.filter(chapter => chapter.selected)}
          onConfirm={(selections) => {
            setIsPageSelectionModalOpen(false);
            generateLandscapeAssessment(selections);
          }}
        />
      </main>
    </div>
  );
};

export default ReportBuilder;