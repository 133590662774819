import React from 'react';
import { Link, Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // You'll need to create this context

const AdminLayout = () => {
  const { isAuthenticated, isAdmin, loading } = useAuth();

  if (loading) {

    return <div>Loading...</div>; // Or any loading component
  }

  if (!isAuthenticated || !isAdmin) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <aside className="w-64 bg-white shadow-md">
        <nav className="mt-5">
          {/* @TODO Add metrics for time from CADTH start to pCPA conclusion, times for review etc.  */}
          <Link to="/admin" className="block py-2 px-4 text-gray-700 hover:bg-gray-200">
            Dashboard
          </Link>
          {/* Add ability to extract CDEC members and link in database as a middleware */}
          <Link to="/admin/drug-reviews" className="block py-2 px-4 text-gray-700 hover:bg-gray-200">
            Drug Reviews
          </Link>
          <Link to="/admin/pcpa-reviews" className="block py-2 px-4 text-gray-700 hover:bg-gray-200">
            pCPA Reviews
          </Link>
          {/* <Link to="/admin/users" className="block py-2 px-4 text-gray-700 hover:bg-gray-200">
            User Management
          </Link> */}
          {/* @TODO Add  */}
          <Link to="/admin/odb-drugs" className="block py-2 px-4 text-gray-700 hover:bg-gray-200">
            ODB Formulary
          </Link>
          {/* @TODO Add  (Pubmed)*/}
          <Link to="/admin/publications" className="block py-2 px-4 text-gray-700 hover:bg-gray-200">
             Publications
          </Link>
          {/* @TODO Add */}
          <Link to="/admin/settings" className="block py-2 px-4 text-gray-700 hover:bg-gray-200">
            Reports
          </Link>
          {/* @TODO Add */}
          <Link to="/admin/report-prompts" className="block py-2 px-4 text-gray-700 hover:bg-gray-200">
            Report Prompts
          </Link>
          {/* @TODO Add at a later stage (Clinical Trials)  */}
          <Link to="/admin/pipeline-monitor" className="block py-2 px-4 text-gray-700 hover:bg-gray-200">
            Pipeline Monitor
          </Link>
          <Link to="/admin/companies" className="block py-2 px-4 text-gray-700 hover:bg-gray-200">
            Companies
          </Link>

          {/* @TODOs */}
          {/* Patient organizations & Summarized input provided */}
          {/* Improve Auth robustness and flow  */}
          {/* KOLs */}
          {/* Providing Support to Patient Groups to Curate and Write their Patient Input */}
          {/* Generate a report building functioanlity that autmatically generates a link to a report and sends them a preview: there needs to be a QC check with me before the report gets send to the customer */}
          {/* CADTH Embargo report reviewer -- see what would be acceptable in industry */}
          {/* Have been using cloud run on GCP (Google Cloud Platform) to host the services for the past few projects they've done. They've hosted on gitlabs and used the runners there which is the equivalent of github actions. and then deploy in whichever environment using cloudrun deploy or cloud build essentially */}
          {/* Unit tests running in the gitlab runners directly  */}
          {/* Set it up so it runs in docker -- dockerize the application  */}
          {/* Read up on github actions */}
        </nav>
      </aside>
      <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
        <div className="container mx-auto px-6 py-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default AdminLayout;